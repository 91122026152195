<template>
  <v-container>
    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-tag</v-icon>
            Preguntas
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col class="d-inline-flex" cols="12" sm="12" v-for="(data, index) in questions" :key="index">
              <v-text-field v-show="false" v-model.number="data.number" label="Numero"></v-text-field>
              <v-checkbox v-model="data.active"></v-checkbox>
              <v-text-field color="secondary" v-model="data.question" outlined label="Nombre">
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <v-btn @click="newQuestion()" color="secondary">
                <v-icon class="mr-2">mdi-content-add</v-icon>
                Nueva pregunta
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" class="mt-6">
      <v-btn @click="saveSurvey()" color="secondary">
        <v-icon class="mr-2">mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import SurveyService from '../services/SurveyService';

export default {
  name: 'survey-home',
  data: () => ({
    questions: [],
    expansionPanelsValue: [0],
  }),
  methods: {
    async getSurveyData() {
      const resp = await SurveyService.getSurvey();
      if (resp.data !== null) {
        this.questions = resp.data.content;
      }
    },
    newQuestion() {
      let nextNumber = 1;
      if (this.questions.length > 0) {
        nextNumber = this.questions[this.questions.length - 1].number + 1;
      }
      this.questions.push({
        number: nextNumber,
        active: true,
        question: '',
      });
    },
    saveSurvey() {
      this.$snotify.async('Por favor espere...', 'Guardando cuestionario', () => new Promise((resolve, reject) => {
        this.disabled = true;
        SurveyService.saveSurvey(this.questions)
          .then(() => {
            resolve({
              title: 'Cuestionario guardado',
              body: 'El cuestionario se guardó correctamente',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch((error) => {
            this.disabled = false;
            console.log(error);
            reject({
              title: 'Error',
              body: `${error.error}`,
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
  },
  mounted() {
    this.getSurveyData();
  },
};
</script>
