<template>
<v-container>
  <clients-table></clients-table>
</v-container>
</template>

<script>
// @ is an alias to /src
import ClientsTable from '../components/ClientsTable.vue';

export default {
  name: 'clients-home',
  components: {
    ClientsTable,
  },
};
</script>
