<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Registrar Salon</h1>
      </v-col>
    </v-row>
    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-tag</v-icon>
            Datos generales
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="upperForm">
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field :rules="requiredRules" v-model="name" :disabled="disabled"
                              color="secondary" outlined label="Identificador del salon"></v-text-field>
              </v-col>
              <v-col cols="12" offset="4" sm="4">
                <v-select v-model="selectedChild" :items="children"
                  item-text="firstname" item-value="id" return-object label="Nino"></v-select>
                  <v-btn small @click="addChild()" class="mr-2" outlined color="green">
                  Agregar nino
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12">
                <v-data-table :items="childrenInClassroom" :loading="isTableLoading"
                :headers="headers" no-data-text="No hay ninos">
                    <template v-slot:item.action="{ item }">
                      <v-row>
                        <v-col sm="5">
                          <v-btn small @click="editItem(item)" class="mr-2" outlined color="green">
                              <v-icon
                              small
                              >
                                  mdi-pencil
                              </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col sm="5">
                          <v-btn small @click="removeItem(item)" outlined color="red">
                              <v-icon
                              small
                              >
                                  mdi-delete
                              </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" class="mt-6">
      <v-btn @click="createProduct()" color="secondary">
        <v-icon class="mr-2">mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ProductService from '../../services/ProductService';

export default {
  name: 'create-product',
  data: () => ({
    disabled: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
      v => v !== ' ' || 'Este campo es obligatorio',
    ],
    headers: [{ text: 'Id', value: 'id', align: 'center' },
      { text: 'Nombre del nino', value: 'firstname', align: 'center' }],
    name: null,
    selectedChild: null,
    children: [],
    childrenInClassroom: [],
    expansionPanelsValue: [0],
    isTableLoading: false,
  }),
  methods: {
    async getChildrenList() {
      this.children = await ProductService.getChildrenList();
    },
    addChild() {
      this.childrenInClassroom.push(this.selectedChild);
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.disabled = false;
    },
    createProduct() {
      const isFormValid = this.$refs.upperForm.validate();
      if (!isFormValid) {
        this.$snotify.error('Por favor, ingresa todos los campos obligatorios', 'Error');
        window.scroll(0, 0);
        return;
      }
      this.$snotify.async('Por favor espere...', 'Creando producto', () => new Promise((resolve, reject) => {
        this.disabled = true;
        ProductService.createProduct(this.name, this.childrenInClassroom)
          .then(() => {
            this.resetFields();
            resolve({
              title: 'Salon creado',
              body: 'El salon se creó correctamente',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch((error) => {
            this.disabled = false;
            console.log(error);
            reject({
              title: 'Error',
              body: `${error.error}`,
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
    remove(item) {
      const index = this.tags.indexOf(item.id);
      if (index >= 0) this.tags.splice(index, 1);
    },
    onBarcodeScanned(barcode) {
      this.barCode = barcode;
    },
  },

  async mounted() {
    this.getChildrenList();
  },
};
</script>
