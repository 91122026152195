<template>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <h1 class="title-text">Alta de promociones</h1>
        </v-col>
      </v-row>
      <v-expansion-panels :value="expansionPanelsValue" multiple>
        <v-expansion-panel value="0" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text"><v-icon color="secondary">mdi-tag</v-icon> Datos generales</p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="upperForm">
              <v-row>
                <v-col cols="12" sm="6">
                  <label for="">Tipo de promoción</label>
                  <v-radio-group v-model="specialType" row>
                    <v-radio color="secondary" label="Descuento" value="1"></v-radio>
                    <v-radio color="secondary" label="Combo" value="2"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <v-row v-if="specialType === '1'">
                <v-col cols="12" sm="5">
                  <v-autocomplete :items="products" item-text="name" item-value="id" return-object
                  v-model="selectedProduct" :disabled="disabled" :loading="productsACLoading"
                  color="secondary" outlined label="Producto"></v-autocomplete>
                </v-col>
                <v-col sm="5">
                  <v-text-field v-model="discount" :disabled="disabled"
                  color="secondary" outlined label="Descuento (%)"></v-text-field>
                </v-col>
                <v-col sm="5">
                  <v-menu v-model="initDateMenu"
                  :close-on-content-click="false"
                  nudge-right="40"
                  transition="scale-transition"
                  offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined
                      color="secondary"
                      v-model="initDate"
                      label="Fecha Inicio"
                      readonly
                      v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="initDate"
                    @input="initDateMenu = false"
                    scrollable no-title></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="5">
                  <v-menu v-model="endDateMenu"
                  :close-on-content-click="false"
                  nudge-right="40"
                  transition="scale-transition"
                  offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined
                      color="secondary"
                      v-model="endDate"
                      label="Fecha Fin"
                      readonly
                      v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="endDate"
                    @input="endDateMenu = false"
                    scrollable no-title></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row v-if="specialType === '2'">
                <v-col sm="5">
                  <v-menu v-model="initDateMenu"
                  :close-on-content-click="false"
                  nudge-right="40"
                  transition="scale-transition"
                  offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined
                      color="secondary"
                      v-model="initDate"
                      label="Fecha Inicio"
                      readonly
                      v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="initDate"
                    @input="initDateMenu = false"
                    scrollable no-title></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="5">
                  <v-menu v-model="endDateMenu"
                  :close-on-content-click="false"
                  nudge-right="40"
                  transition="scale-transition"
                  offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined
                      color="secondary"
                      v-model="endDate"
                      label="Fecha Fin"
                      readonly
                      v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="endDate"
                    @input="endDateMenu = false"
                    scrollable no-title></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-autocomplete :items="products" item-text="name" item-value="id"
                  v-model="selectedProduct" :disabled="disabled"
                  return-object
                  color="secondary" outlined label="Producto"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field v-model="buyAmount" :disabled="disabled"
                  color="secondary" outlined
                  label="Cantidad que debe llevar el cliente"></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-autocomplete v-model="selectedGiftProduct"
                  item-value="id" item-text="name" :items="products"
                  :disabled="disabled"
                  return-object
                  color="secondary" outlined label="Producto de regalo"></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field v-model="giftAmount" :disabled="disabled"
                  color="secondary" outlined
                  label="Cantidad de regalo"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
      <v-row justify="end" class="mt-6">
          <v-btn @click="createSpecial()" color="secondary">
            <v-icon class="mr-2">mdi-content-save</v-icon> Guardar
          </v-btn>
      </v-row>
    </v-container>
</template>

<script>
import ProductService from '../../../services/ProductService';
import DiscountSpecialService from '../../../services/DiscountSpecialService';
import ComboSpecialService from '../../../services/ComboSpecialService';

export default {
  name: 'create-employee',
  data: () => ({
    products: null,
    selectedProduct: null,
    selectedGiftProduct: null,
    buyAmount: null,
    giftAmount: null,
    specialType: null,
    initDateMenu: null,
    initDate: null,
    endDateMenu: null,
    endDate: null,
    discount: null,
    disabled: false,
    productsACLoading: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
    ],
    expansionPanelsValue: [0, 1],
  }),
  methods: {
    createSpecial() {
      this.$snotify.async('Por favor espere..', 'Creando promoción',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          if (this.specialType === '1') {
            this.createDiscountSpecial(resolve, reject);
          } else if (this.specialType === '2') {
            this.createComboSpecial(resolve, reject);
          }
        }));
    },
    createDiscountSpecial(resolve, reject) {
      DiscountSpecialService.createItem(this.selectedProduct.id,
        this.discount, this.initDate, this.endDate)
        .then(() => {
          this.resetFields();
          resolve({
            title: 'Promoción creada',
            body: 'Se creó la promoción correctamente',
            config: {
              timeout: 3000,
            },
          });
        })
        .catch((error) => {
          this.disabled = false;
          reject({
            title: 'Error',
            body: `${error.message}`,
            config: {
              timeout: 3000,
            },
          });
        });
    },
    createComboSpecial(resolve, reject) {
      ComboSpecialService.createItem(this.selectedProduct.id,
        this.selectedGiftProduct.id, this.buyAmount, this.giftAmount,
        this.initDate, this.endDate)
        .then(() => {
          this.resetFields();
          resolve({
            title: 'Promoción creada',
            body: 'Se creó la promoción correctamente',
            config: {
              timeout: 3000,
            },
          });
        })
        .catch((error) => {
          this.disabled = false;
          reject({
            title: 'Error',
            body: `${error.message}`,
            config: {
              timeout: 3000,
            },
          });
        });
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.specialType = this.$route.params.type;
    },
  },
  async mounted() {
    this.specialType = this.$route.params.type;
    this.productsACLoading = true;
    this.products = await ProductService.getProductAutocompleteData();
    this.productsACLoading = false;
  },
};
</script>
