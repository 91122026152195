export class EmployeeTableItem {
  constructor(data) {
    this.id = data.id;
    this.username = data.username;
    this.firstname = data.firstname;
    this.lastname = data.lastname;
    this.role_name = data.role.name;
  }

  get fullname() {
    return `${this.firstname} ${this.lastname}`;
  }
}
