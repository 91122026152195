import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { ListItem } from '../common/models/ListItem';
import { TagsTableItem } from '../common/models/TagsTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = 'system/children';

export default {
  async getTagsList() {
    let resp = null;
    const data = await Axios.get(`${resource}/list`);
    resp = [];
    data.data.forEach((item) => {
      const listItem = new ListItem(item);
      resp.push(listItem);
    });

    return resp;
  },

  async createItem(name, familyId) {
    const payload = new FormData();
    payload.append('name', name);
    payload.append('family_id', familyId);
    let response = null;
    try {
      response = await Axios.post(`${resource}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e.response);
      response = new ErrorResponse(e.response);
      throw response;
    }
    return response;
  },

  async getTable() {
    const response = [];
    let data = null;
    data = await Axios.get(`${resource}/table`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    data.data.data.forEach((item) => {
      const tableItem = new TagsTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async deleteItem(id) {
    let response = null;
    try {
      response = await Axios.delete(`${resource}/${id}`,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getItemById(id) {
    let response = null;
    const data = await Axios.get(`${resource}/${id}`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    response = data.data.data;
    return response;
  },

  async updateItem(id, registerNumber, name, familyId) {
    const payload = new FormData();
    payload.append('name', name);
    payload.append('register_number', registerNumber);
    payload.append('family_id', familyId);
    let response = null;
    try {
      response = await Axios.put(`${resource}/${id}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },
  async downloadReport() {
    const data = await Axios({
      method: 'get',
      url: `${resource}/report`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${TokenUtils.getToken()}`,
      },
    });
    this.forceFileDownload(data, 'reporte_niños.xlsx');
  },
  forceFileDownload(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  },
};
