<template>
  <create-employee></create-employee>
</template>

<script>
// @ is an alias to /src
import CreateEmployee from '@/components/CRUD/Employees/CreateEmployee.vue';

export default {
  name: 'home',
  components: {
    CreateEmployee,
  },
};
</script>
