<template>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <h1 class="title-text">Alta de empleados</h1>
        </v-col>
      </v-row>
      <v-expansion-panels :value="expansionPanelsValue" multiple>
        <v-expansion-panel value="0" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text"><v-icon color="secondary">mdi-tag</v-icon> Datos generales</p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="upperForm">
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select v-show="userRole == 1" :rules="requiredRules" v-model="selectedCampus" :items="campuses"
                  item-text="name" item-value="id" return-object label="Escuela"></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="firstname" :disabled="disabled"
                  color="secondary" outlined label="Nombre(s)"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="lastname" :disabled="disabled"
                  color="secondary" outlined label="Apellido(s)"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="username" :disabled="disabled"
                  color="secondary" outlined label="Nombre de usuario"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="password"
                  :disabled="disabled"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword;"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  color="secondary" outlined label="Contraseña"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select :rules="requiredRules" v-model="selectedRole" :items="roles"
                  item-text="name" item-value="id" return-object label="Rol"></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
      <v-row justify="end" class="mt-6">
          <v-btn @click="createEmployee()" color="secondary">
            <v-icon class="mr-2">mdi-content-save</v-icon> Guardar
          </v-btn>
      </v-row>
    </v-container>
</template>

<script>
import EmployeeService from '../../../services/EmployeeService';
import CampusService from '../../../services/CampusService';
import { TokenUtils } from '../../../utils/TokenUtils';
import { PersonnelRoles } from '../../../common/consts/PersonnelRole';

export default {
  name: 'create-employee',
  data: () => ({
    userRole: 0,
    firstname: null,
    lastname: null,
    username: null,
    password: null,
    campuses: [],
    selectedCampus: null,
    selectedRole: null,
    roles: [],
    showPassword: null,
    disabled: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
    ],
    expansionPanelsValue: [0, 1],
  }),
  methods: {
    async loadCampuses() {
      this.campuses = await CampusService.getList();
    },
    createEmployee() {
      this.$snotify.async('Por favor espere..', 'Dando de alta al empleado',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          EmployeeService.createEmployee(this.selectedCampus.id, this.firstname,
            this.lastname, this.username, this.password, this.selectedRole.id)
            .then(() => {
              this.resetFields();
              resolve({
                title: 'Empleado creado',
                body: 'El empleado se dió de alta correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.disabled = false;
    },
  },
  mounted() {
    this.roles = PersonnelRoles;
    this.loadCampuses();
    const payload = TokenUtils.getJwtData();
    this.userRole = payload.role;

    if (this.userRole !== 1) {
      this.selectedCampus = {
        id: payload.campus,
      };
      this.roles.splice(0, 1);
    }
  },
};
</script>
