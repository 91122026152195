<template>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <h1 class="title-text">Edición de promociones</h1>
        </v-col>
      </v-row>
      <v-expansion-panels :value="expansionPanelsValue" multiple>
        <v-expansion-panel value="0" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text"><v-icon color="secondary">mdi-tag</v-icon> Datos generales</p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="upperForm">
              <v-row>
                <v-col cols="12" sm="5">
                  <v-autocomplete :items="products" item-text="name" item-value="id"
                  readonly disabled filled
                  v-model="selectedProduct" :loading="productsACLoading"
                  color="secondary" outlined label="Producto"></v-autocomplete>
                </v-col>
                <v-col sm="5">
                  <v-text-field v-model="discount" :disabled="disabled"
                  color="secondary" outlined label="Descuento (%)"></v-text-field>
                </v-col>
                <v-col sm="5">
                  <v-menu v-model="initDateMenu"
                  :close-on-content-click="false"
                  nudge-right="40"
                  transition="scale-transition"
                  offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined
                      color="secondary"
                      v-model="initDate"
                      label="Fecha Inicio"
                      readonly
                      v-on="on"
                      :disabled="disabled"
                      ></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="initDate"
                    @input="initDateMenu = false"
                    scrollable no-title></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col sm="5">
                  <v-menu v-model="endDateMenu"
                  :close-on-content-click="false"
                  nudge-right="40"
                  transition="scale-transition"
                  offset-y min-width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field outlined
                      color="secondary"
                      v-model="endDate"
                      label="Fecha Fin"
                      readonly
                      v-on="on"
                      :disabled="disabled"
                      ></v-text-field>
                    </template>
                    <v-date-picker color="secondary" v-model="endDate"
                    @input="endDateMenu = false"
                    scrollable no-title></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>

      </v-expansion-panels>
      <v-row justify="end" class="mt-6">
          <v-btn @click="editSpecial()" color="secondary">
            <v-icon class="mr-2">mdi-content-save</v-icon> Guardar
          </v-btn>
      </v-row>
    </v-container>
</template>

<script>
import ProductService from '../../../services/ProductService';
import DiscountSpecialService from '../../../services/DiscountSpecialService';

export default {
  name: 'create-employee',
  data: () => ({
    products: null,
    selectedProduct: null,
    specialType: null,
    initDateMenu: null,
    initDate: null,
    endDateMenu: null,
    endDate: null,
    discount: null,
    disabled: false,
    productsACLoading: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
    ],
    expansionPanelsValue: [0, 1],
  }),
  methods: {
    async setupPage() {
      const data = await DiscountSpecialService.getItemById(this.$route.params.id);
      this.selectedProduct = data.product.id;
      this.discount = data.discount;
      this.initDate = data.startDt;
      this.endDate = data.endDt;
    },
    editSpecial() {
      this.$snotify.async('Por favor espere..', 'Actualizando promoción',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          DiscountSpecialService.updateItem(this.$route.params.id, this.selectedProduct,
            this.discount, this.initDate, this.endDate)
            .then(() => {
              this.disabled = false;
              resolve({
                title: 'Promoción actualizada',
                body: 'Se actualizó la promoción correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.specialType = this.$route.params.type;
    },
  },
  async mounted() {
    this.specialType = this.$route.params.type;
    this.productsACLoading = true;
    this.products = await ProductService.getProductAutocompleteData();
    this.productsACLoading = false;
    this.setupPage();
  },
};
</script>
