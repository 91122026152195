<template>
  <v-container class="no-padding" fill-height fluid>
    <!-- <div class="blue-background"></div> -->
    <img class="background" src="../assets/fondoondas.png" alt="">
    <login-card></login-card>
  </v-container>
</template>

<script>
// @ is an alias to /src
import LoginCard from '@/components/LoginCard.vue';

export default {
  name: 'login',
  components: {
    LoginCard,
  },
  mounted() {
    localStorage.removeItem('token');
  },
};
</script>

<style lang="less" scoped>
.no-padding {
  padding: 0;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
</style>
