import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { SupplierPurchasOrderData } from '../common/models/SupplierPurchaseOrderData';
import { SupplierPurchasOrderList } from '../common/models/SupplierPurchaseOrderList';
import StoreInventoryTableItem from '../common/models/StoreInventoryTableItem';

// const resource = '/inventory';

export default {
  async saveSupplierPurchaseOrder(storeId, supplierId, total, products) {
    const payload = {
      storeId,
      supplierId,
      total,
      products,
    };
    let resp = null;
    try {
      resp = await Axios.post('/supplier-purchase-order', payload);
    } catch (e) {
      console.error(e.response);
      resp = new ErrorResponse(e.response.data);
    }
    return resp;
  },
  async getSupplierPurchaseOrderList(supplierId, storeId) {
    const limit = 30;
    let resp = await Axios.get('/supplier-purchase-order/supplier', {
      params: {
        supplierId,
        storeId,
        limit,
      },
    });
    resp = resp.data;
    const response = [];
    resp.forEach((x) => {
      response.push(new SupplierPurchasOrderList(x.id, x.createdDt, x.status, x.products));
    });
    return response;
  },
  async getSupplierPurchaseOrderData(id) {
    let resp = await Axios.get(`/supplier-purchase-order/${id}`);
    resp = resp.data;
    const response = new SupplierPurchasOrderData(resp.id, resp.supplierId, resp.total,
      resp.createdDt, resp.products, resp.status);
    return response;
  },
  async updateSupplierPurchaseOrder(supplierPurchaseOrderId, total, products) {
    const payload = { total, products };
    let resp = null;
    try {
      resp = await Axios.put(`/${supplierPurchaseOrderId}/supplier-purchase-order`, payload);
    } catch (e) {
      console.error(e.response);
      resp = new ErrorResponse(e.response.data);
    }
    return resp;
  },
  async cancelSupplierPurchaseOrder(supplierPurchaseOrderId) {
    let resp = null;
    try {
      resp = await Axios.put(`/${supplierPurchaseOrderId}/supplier-purchase-order/cancel`);
    } catch (e) {
      console.error(e.response);
      resp = new ErrorResponse(e.response.data);
    }
    return resp;
  },
  async closeSupplierPurchaseOrder(supplierPurchaseOrderId) {
    let resp = null;
    try {
      resp = await Axios.put(`/${supplierPurchaseOrderId}/supplier-purchase-order/close`);
    } catch (e) {
      console.error(e.response);
      resp = new ErrorResponse(e.response.data);
    }
    return resp;
  },
  async saveMerchEntryData(id, payload) {
    let resp = null;
    try {
      resp = await Axios.post(`supplier-purchase-order/${id}/merch-entry/received-quantity`,
        payload);
    } catch (e) {
      console.error(e.response);
      resp = new ErrorResponse(e.response.data);
    }
    return resp;
  },
  async finishMerchEntry(id, payload) {
    let resp = null;
    try {
      resp = await Axios.post(`supplier-purchase-order/${id}/finish-merch-entry`,
        payload);
    } catch (e) {
      console.error(e.response);
      resp = new ErrorResponse(e.response.data);
    }
    return resp;
  },
  async downloadSupplierPurchaseOrder(id, supplierName) {
    const data = await Axios({
      method: 'get',
      url: '/supplier-purchase-order/download',
      responseType: 'blob',
      params: {
        id,
      },
    });
    const filename = `orden-de-compra-${supplierName}.csv`;
    this.forceFileDownload(data, filename);
  },
  forceFileDownload(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  },
  async getStoresInventoryTableData(limit, offset, search) {
    const resp = await Axios.get('/stores-inventory/table/data', {
      params: {
        limit,
        offset,
        search,
      },
    });
    return resp.data.map((item) => {
      const inst = new StoreInventoryTableItem();
      inst.id = item.id;
      inst.name = item.name;
      inst.model = item.model;
      inst.brandName = item.brandName;

      inst.storesTotal = 0;
      item.inventory.forEach((inventoryItem) => {
        inst[`store${inventoryItem.storeId}`] = inventoryItem.quantity;
        inst.storesTotal += inventoryItem.quantity;
      });

      return inst;
    });
  },
  async getStoresInventoryTableCount(search) {
    const resp = await Axios.get('/stores-inventory/table/count', {
      params: {
        search,
      },
    });
    return resp.data.count ?? 0;
  },
  async saveMerchTransfer(productId, outputStoreId, transferQuantity, inputStoreId) {
    const payload = {
      productId,
      outputStoreId,
      transferQuantity,
      inputStoreId,
    };
    try {
      return await Axios.post('/stores-inventory/merch-transfer', payload);
    } catch (e) {
      console.error(e.response);
      return new ErrorResponse(e.response.data);
    }
  },
};
