<template>
<div>
    <v-row>
        <v-col cols="12" sm="12">
            <h1 class="title-text">Tabla de promociones combos</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" sm="12">
            <v-data-table :items="items" :loading="isTableLoading"
            :headers="headers" no-data-text="No hay promociones">
                <template v-slot:top>
                    <v-spacer></v-spacer>
                    <v-row align="end" justify="end" style="padding-right: 20px;">
                        <v-btn to="/new-special/2" color="primary" dark class="mb-2">
                          <v-icon class="mr-2">mdi-plus</v-icon> Alta de promociones
                        </v-btn>
                    </v-row>
                </template>
                    <template v-slot:item.action="{ item }">
                      <v-row>
                        <v-col sm="5">
                          <v-btn small @click="editItem(item)" class="mr-2" outlined color="green">
                              <v-icon
                              small
                              >
                                  mdi-pencil
                              </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col sm="5">
                          <v-btn small @click="removeItem(item)" outlined color="red">
                              <v-icon
                              small
                              >
                                  mdi-delete
                              </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
            </v-data-table>
        </v-col>
    </v-row>
</div>
</template>

<script>
import ComboSpecialService from '../services/ComboSpecialService';

export default {
  name: 'specials-table',
  data: () => ({
    items: [],
    isTableLoading: true,
    headers: [{ text: 'Id', value: 'id', align: 'center' },
      { text: 'Producto a comprar', value: 'productName', align: 'center' },
      { text: 'Cantidad', value: 'buyAmount', align: 'center' },
      { text: 'Producto de regalo', value: 'giftProductName', align: 'center' },
      { text: 'Cantidad', value: 'giftAmount', align: 'center' },
      { text: 'Fecha de inicio', value: 'startDt', align: 'center' },
      { text: 'Fecha fin', value: 'endDt', align: 'center' },
      { text: 'Acciones', value: 'action', align: 'center' }],
  }),
  methods: {
    editItem(item) {
      this.$router.push(`/${item.id}/edit-combo-special`);
    },
    removeItem(item) {
      this.$swal({
        title: 'Atención',
        text: `¿Estás seguro que quieres dar de baja la promoción de este producto?\n${item.productName}`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, eliminar',
      })
        .then((result) => {
          if (result.value) {
            // Llamar servicio de eliminar y quitar elemento de la vista
            this.$snotify.async('Por favor espere...', 'Eliminando promoción', () => new Promise((resolve, reject) => {
              ComboSpecialService.deleteItem(item.id)
                .then(() => {
                  resolve({
                    title: 'Promoción eliminada',
                    body: 'Se dió de baja con éxito',
                    config: {
                      timeout: 3000,
                    },
                  });
                  this.removeItemFromTable(item);
                })
                .catch(() => {
                  reject({
                    title: 'Error',
                    body: 'No se pudo eliminar la promoción',
                    config: {
                      timeout: 3000,
                    },
                  });
                });
            }));
          }
        });
    },
    removeItemFromTable(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
  },
  async mounted() {
    this.isTableLoading = true;
    this.items = await ComboSpecialService.getTable();
    this.isTableLoading = false;
  },
};
</script>
