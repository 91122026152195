<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Tabla de Unidades de Medida</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" cols="12">
        <template>
          <v-data-table
            :headers="headers"
            :items="items"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-row align="end" justify="end" style="padding-right: 20px;">
                    <v-btn v-on="on" color="primary" dark class="mb-2">
                      <v-icon class="mr-2">mdi-plus</v-icon>
                      Alta de Unidades de medida
                    </v-btn>
                  </v-row>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12" sm="12">
                          <v-text-field color="secondary" :disabled="disabled"
                                        outlined v-model="name" label="Nombre"></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12">
                          <v-text-field color="secondary" :disabled="disabled"
                                        outlined v-model="satName" label="Nombre SAT"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red" dark @click="dialog = false;">Cancelar</v-btn>
                    <v-btn color="secondary darken-1" dark @click="saveItem">Guardar</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:item.action="{ item }">
              <v-row>
                <v-col sm="5">
                  <v-btn small @click="setupEdit(item)" class="mr-2" outlined color="green">
                    <v-icon
                      small
                    >
                      mdi-pencil
                    </v-icon>
                  </v-btn>
                </v-col>
                <v-col sm="5">
                  <v-btn small @click="removeItem(item)" outlined color="red">
                    <v-icon
                      small
                    >
                      mdi-delete
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MeasuringUnitService from '../services/MeasuringUnitService';

export default {
  name: 'brand-home',
  computed: {
    formTitle() {
      return this.id ? 'Editar Unidad de medida' : 'Crear Unidad de medida';
    },
    selectedItem() {
      return this.items.find(x => x.value === this.id);
    },
  },
  data: () => ({
    items: [],
    isTableLoading: false,
    headers: [{ text: 'Id', value: 'value', align: 'center' },
      { text: 'Nombre', value: 'label', align: 'center' },
      { text: 'Nombre SAT', value: 'satName', align: 'center' },
      { text: 'Acciones', value: 'action', align: 'center' }],
    dialog: null,
    name: null,
    satName: null,
    id: null,
    disabled: false,
  }),
  methods: {
    saveItem() {
      if (!this.id) {
        this.createItem();
      } else {
        this.updateItem();
      }
    },
    createItem() {
      this.$snotify.async('Por favor espere..', 'Creando la unidad de medida',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          MeasuringUnitService.createItem(this.name, this.satName)
            .then((resp) => {
              const { id } = resp.data;
              this.items.push({ value: id, label: this.name, satName: this.satName });
              this.resetFields();
              this.disabled = false;
              this.dialog = false;
              resolve({
                title: 'Unidad de medida creada',
                body: 'La unidad de medida se dió de alta correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    resetFields() {
      this.name = null;
      this.satName = null;
      this.id = null;
    },
    setupEdit(item) {
      this.id = item.value;
      this.name = item.label;
      this.satName = item.satName;
      this.dialog = true;
    },
    updateItem() {
      this.$snotify.async('Por favor espere..', 'Editando la unidad de medida',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          MeasuringUnitService.updateItem(this.id, this.name, this.satName)
            .then(() => {
              this.selectedItem.label = this.name;
              this.selectedItem.satName = this.satName;
              this.disabled = false;
              this.dialog = false;
              this.resetFields();
              resolve({
                title: 'Unidad de medida editada',
                body: 'La unidad de medida se actualizó correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    removeItem(item) {
      this.$swal({
        title: 'Atención',
        text: `¿Estás seguro que quieres eliminar esta unidad de medida?\n${item.label}`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, eliminar',
      })
        .then((result) => {
          if (result.value) {
            // Llamar servicio de eliminar y quitar elemento de la vista
            this.$snotify.async(
              'Por favor espere...',
              'Eliminando unidad de medida',
              () => new Promise((resolve, reject) => {
                MeasuringUnitService.deleteItem(item.value)
                  .then(() => {
                    resolve({
                      title: 'Unidad de medida eliminada',
                      body: 'Se dió de baja con éxito',
                      config: {
                        timeout: 3000,
                      },
                    });
                    this.removeItemFromTable(item);
                  })
                  .catch(() => {
                    reject({
                      title: 'Error',
                      body: 'No se pudo eliminar la unidad de medida',
                      config: {
                        timeout: 3000,
                      },
                    });
                  });
              }),
            );
          }
        });
    },
    removeItemFromTable(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    async loadTable() {
      this.isTableLoading = true;
      this.items = await MeasuringUnitService.getTable();
      this.isTableLoading = false;
    },
  },
  async mounted() {
    this.loadTable();
  },
};
</script>
