<template>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <h1 class="title-text">{{name}}</h1>
        </v-col>
      </v-row>
      <v-expansion-panels :value="expansionPanelsValue" multiple>
        <v-expansion-panel value="0" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text"><v-icon color="secondary">mdi-tag</v-icon> Datos generales</p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="upperForm">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="username"
                  color="secondary" outlined label="Nombre de usuario" disabled></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="name"
                  color="secondary" outlined label="Alias" disabled></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="responsibleContact"
                  color="secondary" outlined label="Contacto" disabled></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn @click="resendEmail()" color="secondary">
                    <v-icon class="mr-2">mdi-eye</v-icon> Reenviar correo
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel value="1" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text">
              <v-icon color="secondary">mdi-contact-phone</v-icon> Personas Autorizadas
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-list subheader>
                <v-list-item v-for="authorizedPerson in authorizedPersons" :key="authorizedPerson.id">
                  <v-list-item-content>
                    <v-img
                      max-height="120"
                      max-width="160"
                      :src="getPersonImagePath(authorizedPerson)">
                    </v-img>
                    <v-list-item-title v-text="authorizedPerson.firstname + ' ' + authorizedPerson.lastname">
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel value="1" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text">
              <v-icon color="secondary">mdi-contact-phone</v-icon> Ninos
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row>
              <v-list subheader>
                <v-list-item v-for="child in children" :key="child.id">
                  <v-list-item-content>
                    <v-img
                      max-height="120"
                      max-width="160"
                      :src="getChildImagePath(child)">
                    </v-img>
                    <v-list-item-title v-text="child.firstname">
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row justify="start" class="mt-6">
        <v-col cols="6">
          <v-btn @click="remakePDF()" color="secondary" class="mr-4">
            <v-icon class="mr-2">mdi-eye</v-icon> Actualizar Gafetes
          </v-btn>
          <v-btn @click="downloadPDF()" color="secondary">
            <v-icon class="mr-2">mdi-eye</v-icon> Visualizar Gafetes
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row justify="end" class="mt-6">
          <v-btn @click="updateClient()" color="secondary">
            <v-icon class="mr-2">mdi-content-save</v-icon> Guardar
          </v-btn>
      </v-row> -->
    </v-container>
</template>

<script>
import ClientService from '../../../services/ClientService';

export default {
  name: 'edit-employee',
  data: () => ({
    username: null,
    name: null,
    responsible: null,
    authorizedPersons: null,
    children: null,
    sharedCampus: null,
    disabled: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
    ],
    expansionPanelsValue: [0, 1, 2],
  }),
  methods: {
    async setupPage() {
      const clientId = this.$route.params.id;
      const data = await ClientService.getItemById(clientId);
      this.name = data.name;
      this.username = data.username;
      this.responsible = data.responsible;
      this.authorizedPersons = data.authorized_persons;
      this.children = data.children;
      this.responsibleContact = data.responsible_contact;
      this.hasDocument = data.hasDocument;
      if (this.children.length) {
        this.sharedCampus = this.children[0].campus.id;
      }
    },
    updateClient() {
      // validate params
      if (!this.name) {
        this.$swal('Error', 'Ingresa el nombre de la familia', 'error');
        return;
      }

      this.$snotify.async('Por favor espere..', 'Actualizando familia',
        () => new Promise((resolve, reject) => {
          this.disabled = true;

          const clientId = this.$route.params.id;
          const contactInfo = this.contactInfo.concat(this.newContactInfo);

          ClientService.updateItem(
            clientId,
            this.name,
            contactInfo,
          )
            .then(() => {
              this.disabled = false;
              this.newContactInfo = [];
              this.contactInfo = contactInfo;
              resolve({
                title: 'Familia actualizada',
                body: 'La familia se actualizó correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    async remakePDF() {
      const familyId = this.$route.params.id;
      this.$snotify.async('Por favor espere..', 'Actualizando documento',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          ClientService.remakeBadges(familyId)
            .then(() => {
              this.disabled = false;
              resolve({
                title: 'Documento actualizado',
                body: 'El documento se actualizó correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    async downloadPDF() {
      const familyId = this.$route.params.id;
      window.open(`${process.env.VUE_APP_MEDIA_PATH}/documents/family_badges_${familyId}.pdf`, '_blank').focus();
    },
    async resendEmail() {
      const familyId = this.$route.params.id;
      this.$snotify.async('Por favor espere..', 'Enviando correo',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          ClientService.resendMail(familyId)
            .then(() => {
              this.disabled = false;
              resolve({
                title: 'Correo enviado',
                body: 'El correo se envió correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.disabled = false;
    },
    getFullname(person) {
      return `${person.firstname} ${person.lastname}`;
    },
    getPersonImagePath(person) {
      if (person.photo === null || person.photo === undefined) {
        return '';
      }
      return `${process.env.VUE_APP_MEDIA_PATH}/${person.photo.path}`;
    },
    getChildImagePath(child) {
      if (child.photo === null || child.photo === undefined) {
        return '';
      }
      return `${process.env.VUE_APP_MEDIA_PATH}/${child.photo.path}`;
    },
  },
  mounted() {
    this.setupPage();
  },
};
</script>

<style lang="less">
.v-text-field.v-text-field--enclosed .v-text-field__details {
  display: none !important;
}
.v-text-field__details {
  display: none !important;
}
</style>
