export class ComboSpecialTableItem {
  constructor(data) {
    this.id = data.id;
    this.product = data.product;
    this.giftProduct = data.giftProduct;
    this.buyAmount = data.buyAmount;
    this.giftAmount = data.giftAmount;
    this.startDt = data.startDt;
    this.endDt = data.endDt;
  }

  get productName() {
    return this.product.name;
  }

  get giftProductName() {
    return this.giftProduct.name;
  }
}
