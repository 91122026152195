import Axios from '@/services/Axios';
import { MeasuringUnitListItem } from '../common/models/MeasuringUnitListItem';
import { ErrorResponse } from '../common/models/ErrorResponse';

const resource = '/measuring-unit';

export default {
  async getMeasuringUnitsList() {
    let resp = null;
    const data = await Axios.get(`${resource}/list`);
    resp = [];
    data.data.forEach((item) => {
      const listItem = new MeasuringUnitListItem(item);
      resp.push(listItem);
    });

    return resp;
  },

  async createItem(name, satName) {
    const payload = { name, satName };
    let response = null;
    try {
      // TODO: Add token to header
      // const headers = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // };
      response = await Axios.post(`${resource}`, payload);
    } catch (e) {
      console.error(e.response);
      response = new ErrorResponse(e.response);
      throw response;
    }
    return response;
  },

  async getTable() {
    const response = [];
    let data = null;
    data = await Axios.get(`${resource}/table`);
    data.data.forEach((item) => {
      const tableItem = new MeasuringUnitListItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async deleteItem(id) {
    let response = null;
    try {
      response = await Axios.delete(`${resource}/${id}`);
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getItemById(id) {
    let response = null;
    const data = await Axios.get(`${resource}/${id}`);
    response = data.data;
    return response;
  },

  async updateItem(id, name, satName) {
    const payload = new FormData();
    payload.append('name', name);
    payload.append('satName', satName);
    let response = null;
    try {
      response = await Axios.put(`${resource}/${id}`, payload);
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },
};
