export class EmployeeRequestData {
  constructor(campus, firstname, lastname, username, password, role) {
    this.campus_id = campus;
    this.role_id = role;
    this.firstname = firstname;
    this.lastname = lastname;
    this.username = username;
    this.password = password;
  }
}
