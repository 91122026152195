import Axios from '@/services/Axios';
import { AttendanceTableItem } from '../common/models/AttendanceTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = '/system/attendance';

export default {
  async getAttendanceTable(dateRange) {
    let payload = null;
    if (dateRange.length > 0) {
      const fromDate = dateRange[0].trim();
      let toDate = fromDate;

      if (dateRange.length > 1) {
        toDate = dateRange[1].trim();
      }

      payload = {
        from_date: fromDate,
        to_date: toDate,
      };
    }

    const response = [];
    let resp = null;
    resp = await Axios.get(`${resource}/table`,
      {
        params: payload,
        headers: { Authorization: `Bearer ${TokenUtils.getToken()}` },
      });
    resp.data.data.forEach((item) => {
      const tableItem = new AttendanceTableItem(item);
      response.push(tableItem);
    });
    return response;
  },
  async downloadReport(initDate, endDate) {
    const data = await Axios({
      method: 'get',
      url: `${resource}/report`,
      responseType: 'blob',
      params: {
        from_date: initDate,
        to_date: endDate,
      },
      headers: {
        Authorization: `Bearer ${TokenUtils.getToken()}`,
      },
    });
    let filename = '';
    const startDt = initDate.split('-').join('');
    if (endDate === null || endDate === undefined) {
      filename = `asistencia_${startDt}.xlsx`;
    } else {
      const endDt = endDate.split('-').join('');
      filename = `asistencia_${startDt}-${endDt}.xlsx`;
    }
    this.forceFileDownload(data, filename);
  },
  forceFileDownload(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  },
};
