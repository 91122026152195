<template>
<v-container>
  <specials-table></specials-table>
</v-container>
</template>

<script>
// @ is an alias to /src
import SpecialsTable from '../components/DiscountSpecialTable.vue';

export default {
  name: 'specials-home',
  components: {
    SpecialsTable,
  },
};
</script>
