import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';

export default {
  async auth(username, password) {
    const bodyFormData = new FormData();
    bodyFormData.append('username', username);
    bodyFormData.append('password', password);
    let response = null;

    try {
      response = await Axios.post('/auth/admin', bodyFormData,
        { headers: { 'content-type': 'application/x-www-form-urlenconded' } });
    } catch (e) {
      console.error(e.response.data.message);
      response = new ErrorResponse(e.response.data);
      return response;
    }
    response = response.data;

    return response;
  },
};
