<template>
  <v-container>
    <v-row>
        <v-col cols="12" sm="12">
            <h1 class="title-text">Tabla de Ninos</h1>
        </v-col>
    </v-row>

    <v-row>
      <v-col sm="12" cols="12">
        <template>
          <v-data-table
            :headers="headers"
            :items="validRegisters"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                    label="Buscar"
                    class="mx-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" class="d-flex justify-end">
                  <v-btn to="/new-children" color="secondary" dark class="mb-2 mr-2">
                    <v-icon class="mr-2">mdi-plus</v-icon> Alta de ninos
                  </v-btn>
                  <v-btn small @click="downloadReport()" class="align-self-auto mr-2 pa-4">
                    <v-icon small>
                      mdi-download
                    </v-icon>
                    Descargar reporte
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.action="{ item }">
              <v-row>
                <v-col sm="5">
                  <v-btn small @click="editItem(item)" class="mr-2" outlined color="green">
                      <v-icon
                      small
                      >
                          mdi-pencil
                      </v-icon>
                  </v-btn>
                </v-col>
                <v-col sm="5">
                  <v-btn small @click="removeItem(item)" outlined color="red">
                      <v-icon
                      small
                      >
                          mdi-delete
                      </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
          </v-data-table>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import TagService from '../services/TagService';

export default {
  name: 'category-home',
  computed: {
    formTitle() {
      return this.id ? 'Editar Etiqueta' : 'Crear Etiqueta';
    },
    selectedItem() {
      return this.items.find(x => x.id === this.id);
    },
    validRegisters() {
      return this.items.filter(x => x.id > 0);
    },
  },
  data: () => ({
    search: '',
    items: [],
    isTableLoading: false,
    headers: [
      {
        text: 'Id', value: 'id', align: 'center',
      },
      {
        text: 'Matricula', value: 'registerNumber', align: 'center',
      },
      {
        text: 'Nombre', value: 'firstname', align: 'center',
      },
      {
        text: 'Salon', value: 'classroom', align: 'center',
      },
      {
        text: 'Acciones', value: 'action', align: 'center',
      },
    ],
    dialog: null,
    name: null,
    id: null,
    disabled: false,
    parentTag: -1,
  }),
  methods: {
    saveItem() {
      if (!this.id) {
        this.createItem();
      } else {
        this.updateItem();
      }
    },
    editItem(item) {
      this.$router.push(`/${item.id}/edit-children`);
    },
    createItem() {
      this.$snotify.async('Por favor espere..', 'Dando de alta al nino',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          TagService.createItem(this.name, this.parentTag)
            .then((resp) => {
              const { id } = resp.data;
              this.items.push({ id, name: this.name, parentTag: this.parentTag });
              this.resetFields();
              this.disabled = false;
              this.dialog = false;
              resolve({
                title: 'Nino registrado',
                body: 'El nino se dió de alta correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    resetFields() {
      this.name = null;
      this.id = null;
      this.parentTag = -1;
    },
    setupEdit(item) {
      this.id = item.id;
      this.name = item.name;
    },
    updateItem() {
      this.$snotify.async('Por favor espere..', 'Editando la informacion del nino',
        () => new Promise((resolve, reject) => {
          this.disabled = true;
          TagService.updateItem(this.id, this.name, this.parentTag)
            .then(() => {
              this.selectedItem.name = this.name;
              this.selectedItem.parentTag = this.parentTag;
              this.disabled = false;
              this.dialog = false;
              this.resetFields();
              resolve({
                title: 'Informacion del nino editada',
                body: 'La informacion del nino se actualizó correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              alert('asd');
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    removeItem(item) {
      this.$swal({
        title: 'Atención',
        text: `¿Estás seguro que quieres dar de baja a ${item.firstname}?`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, eliminar',
      })
        .then((result) => {
          if (result.value) {
            // Llamar servicio de eliminar y quitar elemento de la vista
            this.$snotify.async('Por favor espere...', 'Dando de baja', () => new Promise((resolve, reject) => {
              TagService.deleteItem(item.id)
                .then(() => {
                  resolve({
                    title: 'Baja de nino',
                    body: 'Se dió de baja con éxito',
                    config: {
                      timeout: 3000,
                    },
                  });
                  this.removeItemFromTable(item);
                })
                .catch(() => {
                  reject({
                    title: 'Error',
                    body: 'No se pudo dar de baja al nino',
                    config: {
                      timeout: 3000,
                    },
                  });
                });
            }));
          }
        });
    },
    removeItemFromTable(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    async loadTable() {
      this.isTableLoading = true;
      this.items = await TagService.getTable();
      this.isTableLoading = false;
    },
    downloadReport() {
      TagService.downloadReport();
    },
  },
  async mounted() {
    this.loadTable();
  },
};
</script>
