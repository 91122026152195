<template>
  <v-container>
    <v-row>
      <v-col cols='12' sm='12'>
        <h1 class='title-text'>Tabla de formularios</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols='12' sm='12'>
        <v-data-table
          :items='items'
          :loading='isTableLoading'
          :headers='headers'
          :search="search"
          no-data-text='No hay formularios'
          :item-class="itemRowBackground"
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  label="Buscar por nombre"
                  class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRange"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Filtrar por fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    no-title
                    scrollable
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dateMenuReset"
                    >
                      Limpiar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="dateMenuSave"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3">
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.action="{ item }">
            <v-dialog v-model="dialog" max-width="500px" :retain-focus="false">
              <template v-slot:activator="{ on }">
                <v-row>
                  <v-col sm="12">
                    <v-btn v-on="on" small @click="showDetails(item)" outlined :color="iconColor(item)">
                        <v-icon
                        small
                        >
                            mdi-feature-search
                        </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Cuestionario</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col v-for="(data, index) in surveyQuestions" :key="index" cols="12" sm="12">
                        <p v-if="data.active">
                          {{data.question}}
                        </p>
                        <div>
                          <v-btn plain width="50%" class="no-click"
                            :color="answerColor(answers[index], true)">Si</v-btn>
                          <v-btn plain width="50%" class="no-click"
                            :color="answerColor(answers[index], false)">No</v-btn>
                        </div>
                        <!-- <v-checkbox v-model="answers[index].value" disabled></v-checkbox> -->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" dark @click="closeDialog()">Cerrar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormService from '../services/FormService';
import SurveyService from '../services/SurveyService';

export default {
  name: 'form-home',
  data: () => ({
    search: '',
    menu: false,
    dateRange: [],
    dialog: false,
    isTableLoading: false,
    surveyQuestions: [],
    items: [],
    answers: [],
    headers: [
      {
        text: 'Id', value: 'id', align: 'center',
      },
      {
        text: 'Estatus', value: 'status', align: 'center',
      },
      {
        text: 'Fecha de expiracion', value: 'expirationDate', align: 'center', filterable: false,
      },
      {
        text: 'Corresponde a', value: 'Owner', align: 'center',
      },
      {
        text: 'Acciones', value: 'action', align: 'center',
      },
    ],
    expansionPanelsValue: [0],
  }),
  watch: {
    dialog(newValue) {
      if (!newValue) {
        this.closeDialog();
      }
    },
  },
  computed: {
    dateRangeText() {
      if (this.dateRange.length === 0) {
        return '';
      }
      if (this.dateRange.length === 1) {
        return this.dateRange[0];
      }

      if (this.dateRange[1] < this.dateRange[0]) {
        [this.dateRange[0], this.dateRange[1]] = [this.dateRange[1], this.dateRange[0]];
      }

      return this.dateRange.join(' - ');
    },
  },
  methods: {
    itemRowBackground(item) {
      return !item.formStatus ? 'form-status-alert' : '';
    },
    iconColor(item) {
      return item.formStatus ? '#27cf3e' : '#b33834';
    },
    answerColor(item, expectedValue) {
      return item.value === expectedValue ? '#00F' : '#000';
    },
    async showDetails(item) {
      const surveyId = item.surveyAnswers.survey_id;
      const data = await this.getCurrentSurvey(surveyId);
      data.data.data.content.forEach((question) => {
        if (question.active) {
          this.surveyQuestions.push(question);
        }
      });
      this.answers = item.surveyAnswers.answers;
      this.answers.forEach((answer) => {
        const temp = answer;
        temp.value = this.getAnswerValue(answer.value);
      });
    },
    async getCurrentSurvey(surveyId) {
      const data = await SurveyService.getSurveyFromId(surveyId);
      return data;
    },
    async getFormsData() {
      const data = await FormService.getFormDataTable(this.dateRange);
      this.items = data;
    },
    closeDialog() {
      this.dialog = false;
      this.surveyQuestions = [];
      this.answers = [];
    },
    dateMenuSave() {
      this.$refs.menu.save(this.dateRange);
      this.getFormsData();
    },
    dateMenuReset() {
      this.menu = false;
      this.$refs.menu.save('');
      this.dateRange = [];
      this.getFormsData();
    },
    getAnswerValue(answer) {
      if (answer === false || answer === 'false') {
        return false;
      }
      if (answer === true || answer === 'true') {
        return true;
      }
      return false;
    },
  },
  mounted() {
    const yourDate = new Date();
    this.dateRange.push(yourDate.toISOString().split('T')[0]);
    this.getFormsData();
  },
};
</script>
<style>
  .form-status-alert {
    background-color: #f56969;
  }
  .form-status-alert:hover {
    background-color: #f55d5d !important;
  }
  .no-click {
    pointer-events: none;
  }
</style>
