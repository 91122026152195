import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { ComboSpecialTableItem } from '../common/models/ComboSpecialTableItem';

const resource = '/combo-special';

export default {
  async createItem(productId, giftProductId, buyAmount, giftAmount, startDt, endDt) {
    const payload = {
      productId,
      giftProductId,
      buyAmount,
      giftAmount,
      startDt,
      endDt,
    };
    let response = null;
    try {
      // TODO: Add token to header
      // const headers = {
      //   headers: {
      //     'Content-Type': 'application/json',
      //   },
      // };
      response = await Axios.post(`${resource}`, payload);
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getTable() {
    const response = [];
    let data = null;
    data = await Axios.get(`${resource}/table`);
    data.data.forEach((item) => {
      const tableItem = new ComboSpecialTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async deleteItem(id) {
    let response = null;
    try {
      response = await Axios.delete(`${resource}/${id}`);
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getItemById(id) {
    let response = null;
    const data = await Axios.get(`${resource}/${id}`);
    response = data.data;
    return response;
  },

  async updateItem(id, productId, giftProductId, buyAmount, giftAmount, startDt, endDt) {
    const payload = new FormData();
    payload.append('productId', productId);
    payload.append('giftProductId', giftProductId);
    payload.append('buyAmount', buyAmount);
    payload.append('giftAmount', giftAmount);
    payload.append('startDt', startDt);
    payload.append('endDt', endDt);
    let response = null;
    try {
      response = await Axios.put(`${resource}/${id}`, payload);
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },
};
