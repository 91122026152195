<template>
<div>
    <v-row>
        <v-col cols="12" sm="12">
            <h1 class="title-text">Tabla de personal</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" sm="12">
            <v-data-table :items="items" :loading="isTableLoading"
            :headers="headers" :search="search" no-data-text="No hay personal registrado">
                <template v-slot:top>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          single-line
                          hide-details
                          label="Buscar"
                          class="mx-4"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="d-flex justify-end">
                        <v-btn to="/new-employee" color="secondary" dark class="mb-2">
                          <v-icon class="mr-2">mdi-plus</v-icon> Alta de personal
                        </v-btn>
                      </v-col>
                    </v-row>
                </template>
                    <template v-slot:item.action="{ item }">
                      <v-row>
                        <v-col sm="5">
                          <v-btn small @click="editItem(item)" class="mr-2" outlined color="green">
                              <v-icon
                              small
                              >
                                  mdi-pencil
                              </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col sm="5">
                          <v-btn small @click="removeItem(item)" outlined color="red">
                              <v-icon
                              small
                              >
                                  mdi-delete
                              </v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
            </v-data-table>
        </v-col>
    </v-row>
</div>
</template>

<script>
import EmployeeService from '../services/EmployeeService';

export default {
  name: 'employees-table',
  data: () => ({
    search: '',
    items: [],
    isTableLoading: true,
    headers: [
      {
        text: 'Id', value: 'id', align: 'center', filterable: false,
      },
      {
        text: 'Nombre del empleado', value: 'fullname', align: 'center',
      },
      {
        text: 'Usuario', value: 'username', align: 'center',
      },
      {
        text: 'Acciones', value: 'action', align: 'center',
      },
    ],
  }),
  methods: {
    editItem(item) {
      this.$router.push(`/${item.id}/edit-employee`);
    },
    removeItem(item) {
      this.$swal({
        title: 'Atención',
        text: `¿Estás seguro que quieres dar de baja a este empleado?\n${item.fullname}`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, eliminar',
      })
        .then((result) => {
          if (result.value) {
            // Llamar servicio de eliminar y quitar elemento de la vista
            this.$snotify.async('Por favor espere...', 'Eliminando Empleado', () => new Promise((resolve, reject) => {
              EmployeeService.deleteEmployee(item.id)
                .then(() => {
                  resolve({
                    title: 'Empleado dado de baja',
                    body: 'Se dió de baja con éxito',
                    config: {
                      timeout: 3000,
                    },
                  });
                  this.removeItemFromTable(item);
                })
                .catch(() => {
                  reject({
                    title: 'Error',
                    body: 'No se pudo dar de baja al empleado',
                    consig: {
                      timeout: 3000,
                    },
                  });
                });
            }));
          }
        });
    },
    removeItemFromTable(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
  },
  async mounted() {
    this.isTableLoading = true;
    this.items = await EmployeeService.getEmployeesTable();
    this.isTableLoading = false;
  },
};
</script>
