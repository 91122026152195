import Axios from '@/services/Axios';
import { FormTableItem } from '../common/models/FormTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = '/system/forms';

export default {
  async getFormDataTable(dateRange) {
    let payload = null;

    if (dateRange.length > 0) {
      const fromDate = dateRange[0].trim();
      let toDate = fromDate;

      if (dateRange.length > 1) {
        toDate = dateRange[1].trim();
      }

      payload = {
        from_date: fromDate,
        to_date: toDate,
      };
    }

    const response = [];
    let resp = null;
    resp = await Axios.get(`${resource}/table`,
      {
        params: payload,
        headers: { Authorization: `Bearer ${TokenUtils.getToken()}` },
      });

    resp.data.data.forEach((item) => {
      const tableItem = new FormTableItem(item);
      response.push(tableItem);
    });
    return response;
  },
};
