<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Configuracion del dispositivo de acceso</h1>
      </v-col>
    </v-row>
    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-tag</v-icon>
            Datos generales
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="upperForm">
            <v-row>
              <v-col cols="4">
                <v-checkbox small outlined
                  v-model="config.check_temperature"
                  label="Revisar temperatura">
                  <v-icon small>
                    circle-edit-outline
                  </v-icon>
                </v-checkbox>
              </v-col>
              <v-col cols="4">
                <v-checkbox small outlined
                  v-model="config.check_mask"
                  label="Revisar cubrebocas">
                  <v-icon small>
                    circle-edit-outline
                  </v-icon>
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <v-text-field :rules="requiredRules" v-model="config.max_temperature"
                  color="secondary" outlined label="Revisar temperatura"></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-text-field :rules="requiredRules" v-model="config.show_results_delay"
                  color="secondary" outlined label="Tiempo mostrando resultados"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="end" class="mt-6">
              <v-btn @click="save()" color="secondary">
                <v-icon class="mr-2">mdi-content-save</v-icon>
                Guardar
              </v-btn>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import CampusService from '../services/CampusService';

export default {
  name: 'campus-config',
  data: () => ({
    campusId: 0,
    config: {
      check_temperature: null,
      check_mask: null,
      max_temperature: null,
      show_results_delay: null,
    },
    expansionPanelsValue: [0],
  }),
  methods: {
    async loadCampusConfig() {
      const resp = await CampusService.loadCampusConfig(this.campusId);
      this.config = resp.data.data;
    },
    async save() {
      this.$snotify.async('Por favor espere...', 'Actualizando configuracion', () => new Promise((resolve, reject) => {
        CampusService.saveCampusConfig(this.campusId, this.config)
          .then(() => {
            resolve({
              title: 'Configuracion actualizada',
              body: 'La configuracion se actualizó correctamente',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch((error) => {
            this.disabled = false;
            console.log(error);
            reject({
              title: 'Error',
              body: `${error.error}`,
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
  },
  mounted() {
    this.campusId = this.$route.params.id;
    this.loadCampusConfig();
  },
};
</script>

<style>

</style>
