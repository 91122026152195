import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { EmployeeRequestData } from '../common/models/EmployeeRequestData';
import { EmployeeTableItem } from '../common/models/EmployeeTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = 'system/personnel';

export default {
  async createEmployee(campusId, firstname, lastname, username, password, role) {
    // const tokenData = TokenUtils.getJwtData();
    const payload = new EmployeeRequestData(campusId, firstname, lastname, username, password, role);
    let response = null;
    try {
      response = await Axios.post(`${resource}`, payload);
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e.response.data);
      throw response;
    }
    return response;
  },

  async getEmployeesTable() {
    const response = [];
    let resp = null;
    resp = await Axios.get(`${resource}/table`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    resp.data.data.forEach((item) => {
      const tableItem = new EmployeeTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async deleteEmployee(id) {
    let response = null;
    try {
      response = await Axios.delete(`${resource}/${id}`,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getEmployeeById(id) {
    let response = null;
    const data = await Axios.get(`${resource}/${id}`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    response = data.data;
    return response;
  },

  async updateEmployee(id, campusId, firstname, lastname, username, role) {
    const payload = new FormData();
    payload.append('firstname', firstname);
    payload.append('lastname', lastname);
    payload.append('username', username);
    payload.append('campus_id', campusId);
    payload.append('role_id', role);
    let response = null;
    try {
      response = await Axios.put(`${resource}/${id}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },
};
