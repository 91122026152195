var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('h1',{staticClass:"title-text"},[_vm._v("Tabla de formularios")])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-data-table',{attrs:{"items":_vm.items,"loading":_vm.isTableLoading,"headers":_vm.headers,"search":_vm.search,"no-data-text":"No hay formularios","item-class":_vm.itemRowBackground},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","single-line":"","hide-details":"","label":"Buscar por nombre"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateRange,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateRange=$event},"update:return-value":function($event){_vm.dateRange=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Filtrar por fecha","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","scrollable":"","range":""},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.dateMenuReset}},[_vm._v(" Limpiar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.dateMenuSave}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}})],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"500px","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-row',[_c('v-col',{attrs:{"sm":"12"}},[_c('v-btn',_vm._g({attrs:{"small":"","outlined":"","color":_vm.iconColor(item)},on:{"click":function($event){return _vm.showDetails(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-feature-search ")])],1)],1)],1)]}}],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Cuestionario")])]),_c('v-card-text',[_c('v-container',[_c('v-row',_vm._l((_vm.surveyQuestions),function(data,index){return _c('v-col',{key:index,attrs:{"cols":"12","sm":"12"}},[(data.active)?_c('p',[_vm._v(" "+_vm._s(data.question)+" ")]):_vm._e(),_c('div',[_c('v-btn',{staticClass:"no-click",attrs:{"plain":"","width":"50%","color":_vm.answerColor(_vm.answers[index], true)}},[_vm._v("Si")]),_c('v-btn',{staticClass:"no-click",attrs:{"plain":"","width":"50%","color":_vm.answerColor(_vm.answers[index], false)}},[_vm._v("No")])],1)])}),1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){return _vm.closeDialog()}}},[_vm._v("Cerrar")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }