<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">
          <v-icon color="secondary">mdi-tag</v-icon>
          Entrada de mercancía
        </h1>
      </v-col>
    </v-row>

    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-magnify</v-icon>
            Buscar orden de compra
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-autocomplete hide-details outlined
                              :disabled="disableSelectStore"
                              v-model="selectedStore"
                              @change="storeChanged"
                              no-data-text="No encontrado..."
                              :items="stores"
                              item-text="label"
                              item-value="value"
                              color="secondary"
                              label="Sucursal">
              </v-autocomplete>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete hide-details outlined
                              v-model="supplierId"
                              @change="supplierChanged"
                              no-data-text="No hay proveedores"
                              :items="suppliers" item-text="label" item-value="value"
                              color="secondary" label="Proveedor"></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete hide-details outlined
                              v-model="supplierPurchaseOrderId"
                              @change="supplierPurchaseOrderChanged"
                              no-data-text="No hay órdenes"
                              :items="supplierOrders" item-text="inventoryLabel" item-value="id"
                              color="secondary" label="Órdenes de compra"></v-autocomplete>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel value="1" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-plus</v-icon>
            Entrada de mercancía
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row id="me-header-container" v-if="supplierPurchaseOrderData">
            <v-col cols="12" sm="1">
              <v-checkbox
                v-if="supplierPurchaseOrderData.status === 1"
                @change="forcedSelectAll = false"
                color="secondary"
                v-model="selectAll"
              ></v-checkbox>
            </v-col>
            <v-col align-self="center" cols="12" sm="7">
              <h2 class="title">Producto</h2>
            </v-col>
            <v-col align-self="center" cols="12" sm="2">
              <h2 class="title text-center">Pedido</h2>
            </v-col>
            <v-col align-self="center" cols="12" sm="2">
              <h2 class="title text-center">Recibido</h2>
            </v-col>
          </v-row>
          <div v-if="supplierPurchaseOrderData">
            <v-row
              v-for="(item, i) in supplierPurchaseOrderData.products" v-bind:key="i">
              <v-col cols="12" sm="1">
                <v-checkbox
                  :disabled="supplierPurchaseOrderData.status !== 1 || item.alreadyCounted === 1"
                  color="secondary"
                  @change="onCheckboxChanged()"
                  v-model="item.isOk"
                ></v-checkbox>
              </v-col>
              <v-col cols="12" sm="7" align-self="center">
                <h3 class="subtitle">{{ item.name }}</h3>
              </v-col>
              <v-col align-self="center" cols="12" sm="2">
                <h3 class="text-center subtitle">{{ item.quantity }}</h3>
              </v-col>
              <v-col align-self="center" cols="12" sm="2">
                <h3 v-if="supplierPurchaseOrderData.status === 1 && !item.isOk" class="text-center subtitle">N/A</h3>
                <v-text-field v-else-if="supplierPurchaseOrderData.status === 1 && item.alreadyCounted !== 1"
                              outlined
                              color="secondary"
                              hide-details
                              v-model="item.receivedQuantity"></v-text-field>
                <h3 v-else-if="supplierPurchaseOrderData.status === 2 && item.alreadyCounted !== 1"
                    class="text-center subtitle">N/A</h3>
                <h3 v-else-if="item.alreadyCounted === 1" class="text-center subtitle">{{ item.receivedQuantity }}</h3>
              </v-col>
            </v-row>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row v-if="supplierPurchaseOrderData && supplierPurchaseOrderData.status === 1"
           justify="end" align="end">
      <v-btn @click="saveChanges" class="mr-4" color="secondary">
        <v-icon>mdi-save</v-icon>
        {{ saveAction }}
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import SupplierService from '../services/SupplierService';
import InventoryService from '../services/InventoryService';
import { TokenUtils } from '../utils/TokenUtils';
import UserService from '../services/UserService';

export default {
  name: 'merch-entry',

  data: () => ({
    selectAll: true,
    expansionPanelsValue: [0, 1],
    items: [],
    suppliers: [],
    supplierId: null,
    supplierOrders: [],
    supplierPurchaseOrderId: null,
    supplierPurchaseOrderData: null,
    payload: [],
    purchaseOrders: [],
    saveAction: 'Guardar',
    forcedSelectAll: false,

    stores: [],
    selectedStore: null,
    disableSelectStore: false,
  }),

  methods: {
    async supplierChanged() {
      this.supplierOrders = await InventoryService.getSupplierPurchaseOrderList(
        this.supplierId,
        this.selectedStore,
      );
    },

    async supplierPurchaseOrderChanged() {
      const resp = await InventoryService.getSupplierPurchaseOrderData(this.supplierPurchaseOrderId);
      this.supplierPurchaseOrderData = resp;

      // all the items are always selected
      this.saveAction = 'Terminar';
    },

    async saveChanges() {
      // eslint-disable-next-line max-len
      const itemsToSave = this.supplierPurchaseOrderData.products.filter(item => item.isOk && item.alreadyCounted === 0);
      if (!itemsToSave || itemsToSave.length === 0) {
        this.$swal('Atención', 'No se han seleccionado elementos para guardar.', 'warning');
        return;
      }

      const pendingValues = this.supplierPurchaseOrderData.products.map(item => item.isOk).includes(false);
      const confirmationText = !pendingValues
        ? 'Todos los elementos han sido seleccionados. Se dará por terminada la orden. ¿Desea continuar?'
        : 'Se procedera a guardar los elementos seleccionados. ¿Desea continuar?';

      const result = await this.$swal({
        title: 'Atención',
        text: confirmationText,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonText: 'Si',
      });

      if (!result.value) {
        return;
      }

      this.payload = itemsToSave.map(x => (
        {
          id: x.rowId,
          productId: x.id,
          receivedQuantity: parseInt(x.receivedQuantity, 10),
        }
      ));

      // Llamar servicio de eliminar y quitar elemento de la vista
      this.$snotify.async('Por favor espere...', 'Guardando cambios', () => new Promise((resolve, reject) => {
        InventoryService.saveMerchEntryData(this.supplierPurchaseOrderId, { products: this.payload })
          .then(() => {
            resolve({
              title: 'Cambios guardados',
              body: 'Se guardaron los cambios con éxito',
              config: {
                timeout: 3000,
              },
            });
            this.payload.forEach((x) => {
              const item = this.supplierPurchaseOrderData.products.find(y => y.rowId
                === x.id);
              if (item) {
                item.alreadyCounted = 1;
              }
            });

            if (!pendingValues) {
              this.clearFields();
              this.supplierChanged();
            }
          })
          .catch(() => {
            reject({
              title: 'Error',
              body: 'No se pudieron guardar los cambios',
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },

    clearFields() {
      this.supplierId = null;
      this.supplierPurchaseOrderId = null;
      this.supplierPurchaseOrderData = null;
      this.payload = [];
      this.supplierOrders = null;
      this.saveAction = 'Guardar';
    },

    onCheckboxChanged() {
      const pendingValues = this.supplierPurchaseOrderData.products.map(item => item.isOk).includes(false);
      this.saveAction = !pendingValues ? 'Terminar' : 'Guardar';
      this.forcedSelectAll = true;
      this.selectAll = !pendingValues;
    },

    async storeChanged() {
      this.searchSupplier = null;
      this.supplierOrders = [];
      this.clearFields();
    },
  },

  watch: {
    selectAll: function selectAllChanged(newValue) {
      if (!this.supplierPurchaseOrderData || !this.supplierPurchaseOrderData.products || this.forcedSelectAll) {
        // reset forced select all param
        if (this.forcedSelectAll) {
          this.forcedSelectAll = false;
        }

        return;
      }

      this.supplierPurchaseOrderData.products.forEach((item) => {
        const product = item;
        if (product.alreadyCounted !== 1) {
          product.isOk = newValue;
        }
      });

      const pendingValues = this.supplierPurchaseOrderData.products.map(item => item.isOk).includes(false);
      this.saveAction = !pendingValues ? 'Terminar' : 'Guardar';
    },
  },

  async mounted() {
    const payload = TokenUtils.getJwtData();

    // eslint-disable-next-line camelcase
    const { user_claims: userClaims, identity: userId } = payload;
    const { store } = userClaims;

    this.suppliers = await SupplierService.getTable();
    this.stores = await UserService.getUserStores(userId);

    this.disableSelectStore = !!store && store.length === 1;

    // pre-select the first store or the user store if available
    if (this.disableSelectStore && !!this.stores) {
      [this.selectedStore] = store;
    } else if (!!this.stores) {
      this.selectedStore = this.stores[0].value;
    }
  },
};
</script>

<style>
.subtitle {
  font-weight: 400;
  font-size: 1.05rem;
}

#me-header-container {
  border-bottom: solid #0d1d4e 1px !important;
}
</style>
