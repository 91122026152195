<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Mercanc&iacute;a en sucursales</h1>
      </v-col>
    </v-row>

    <v-col cols="12" sm="8">
      <v-row>
        <v-text-field @keypress.enter="onSearch"
                      color="secondary"
                      v-model="search"
                      label="Búsqueda"
                      outlined></v-text-field>
        <v-btn @click="onSearch" fab class="ml-2" color="primary" elevation="2">
          <v-icon medium>mdi-magnify</v-icon>
        </v-btn>
      </v-row>
    </v-col>

    <v-row>
      <v-col sm="12" cols="12">
        <v-data-table
          :loading="isTableLoading"
          :headers="headers"
          :server-items-length="itemsCount"
          :items="items"
          no-data-text="Sin datos para mostrar"
          class="elevation-1"
          :page.sync="page"
          @update:page="updatePage($event)"
        >
          <template v-slot:item.storesTotal="{ value }">
            <span class="font-weight-bold">{{ value }}</span>
          </template>
          <template v-slot:item.merchTransfer="{ item }">
            <v-btn fab small color="secondary" @click.stop="onMerchTransfer(item)">
              <v-icon>mdi-repeat</v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card flat>
        <v-toolbar
          flat
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="onMerchTransferCancel()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title class="white--text">Traspaso de mercanc&iacute;a</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onMerchTransferSave()"
            >
              Guardar
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card v-if="dialogItem">
          <v-card-title>Art&iacute;culo: <b>{{ dialogItem.name }}</b></v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" >
                <v-autocomplete hide-details outlined
                                v-model="outputSelectedStore"
                                no-data-text="No encontrado..."
                                :items="stores"
                                item-text="label"
                                item-value="value"
                                color="secondary"
                                label="Sucursal de salida">
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" >
                <v-text-field
                  label="Cantidad"
                  type="number"
                  v-model="transferQuantity"
                  prepend-icon="mdi-arrow-right-bold"
                  append-outer-icon="mdi-arrow-right-bold"
                  required>
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-autocomplete hide-details outlined
                                v-model="inputSelectedStore"
                                no-data-text="No encontrado..."
                                :items="stores"
                                item-text="label"
                                item-value="value"
                                color="secondary"
                                label="Sucursal de entrada">
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

import InventoryService from '../services/InventoryService';
import { TokenUtils } from '../utils/TokenUtils';
import UserService from '../services/UserService';
import { ErrorResponse } from '../common/models/ErrorResponse';

export default {
  name: 'stores-inventory',
  data: () => ({
    search: '',

    isTableLoading: false,
    baseHeaders: [
      {
        text: 'Nombre del producto',
        value: 'name',
        align: 'left',
        sortable: false,
      },
      {
        text: 'Modelo',
        value: 'model',
        align: 'center',
        sortable: false,
      },
      {
        text: 'Marca',
        value: 'brandName',
        align: 'center',
        sortable: false,
      },
    ],
    dummyItems: [],
    limit: 10,
    offset: 0,
    items: [],
    itemsCount: -1,
    page: 0,

    stores: [],

    dialog: false,
    dialogItem: null,
    outputSelectedStore: null,
    transferQuantity: null,
    inputSelectedStore: null,
  }),
  methods: {
    // eslint-disable-next-line
    async getTableData(limit, offset) {
      if (this.itemsCount === -1) {
        this.itemsCount = await InventoryService.getStoresInventoryTableCount(this.search);
      }

      this.items = [];
      this.items = await InventoryService.getStoresInventoryTableData(limit, offset, this.search);
      this.items.forEach((item) => {
        const modItem = item;
        this.stores.forEach((store) => {
          const key = `store${store.value}`;
          if (!(key in modItem)) {
            modItem[key] = 0;
          }
        });
      });
    },
    updatePage(page) {
      this.offset = this.limit * (page - 1);
      this.getTableData(this.limit, this.offset);
    },
    onSearch() {
      this.offset = 0;
      this.page = 0;
      this.itemsCount = -1;
      this.getTableData(this.limit, this.offset);
    },
    onMerchTransfer(item) {
      this.dialogItem = item;
      this.dialog = true;
    },
    async onMerchTransferSave() {
      // todo: validate data
      const transferQuantity = Number(this.transferQuantity);

      const response = await InventoryService.saveMerchTransfer(
        this.dialogItem.id,
        this.outputSelectedStore,
        transferQuantity,
        this.inputSelectedStore,
      );

      if (response instanceof ErrorResponse) {
        this.$swal('Error', response.error, 'error');
        return;
      }

      await this.$swal('Correcto', response.data.message, 'success');

      this.dialogItem = null;
      this.outputSelectedStore = null;
      this.transferQuantity = null;
      this.inputSelectedStore = null;
      this.dialog = false;

      await this.getTableData(this.limit, this.offset);
    },
    onMerchTransferCancel() {
      this.dialogItem = null;
      this.outputSelectedStore = null;
      this.transferQuantity = null;
      this.inputSelectedStore = null;
      this.dialog = false;
    },
  },
  computed: {
    headers() {
      const headers = [...this.baseHeaders];
      if (this.stores) {
        // eslint-disable-next-line arrow-body-style
        const storesCols = this.stores.map((item) => {
          return {
            text: item.label,
            value: `store${item.value}`,
            align: 'right',
            sortable: false,
          };
        });
        headers.push(...storesCols);
      }
      headers.push({
        text: 'Total',
        value: 'storesTotal',
        align: 'right',
        sortable: false,
      });
      if (this.stores.length > 1) {
        headers.push({
          text: 'Traspaso',
          value: 'merchTransfer',
          align: 'center',
          sortable: false,
        });
      }
      return headers;
    },
  },
  async mounted() {
    const payload = TokenUtils.getJwtData();

    const { identity: userId } = payload;
    this.stores = await UserService.getUserStores(userId);

    await this.getTableData(this.limit, 0);
  },
};
</script>
