<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Editar Salon</h1>
      </v-col>
    </v-row>
    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-tag</v-icon>
            Datos generales
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="upperForm">
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field :rules="requiredRules" v-model="name" :disabled="disabled"
                              color="secondary" outlined label="Identificador del salon"></v-text-field>
              </v-col>
              <v-col cols="12" offset="4" sm="4">
                <v-select v-model="selectedChild" :items="children"
                  item-text="firstname" item-value="id" return-object label="Nino"></v-select>
                  <v-btn small @click="addChild()" class="mr-2" outlined color="green">
                  Agregar nino
                </v-btn>
              </v-col>
              <v-col cols="12" sm="12">
                <v-data-table :items="childrenInClassroom" :loading="isTableLoading"
                :headers="headers" no-data-text="No hay ninos">
                  <template v-slot:item.action="{ item }">
                    <v-row>
                      <v-col sm="12">
                        <v-btn small @click="removeItem(item)" outlined color="red">
                            <v-icon
                            small
                            >
                                mdi-delete
                            </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" class="mt-6">
      <v-btn @click="editProduct()" color="secondary">
        <v-icon class="mr-2">mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ProductService from '../services/ProductService';

export default {
  name: 'edit-product',
  data: () => ({
    disabled: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
      v => v !== ' ' || 'Este campo es obligatorio',
    ],
    headers: [{ text: 'Id', value: 'id', align: 'center' },
      { text: 'Nombre del nino', value: 'firstname', align: 'center' },
      { text: 'Acciones', value: 'action', align: 'center' }],
    name: null,
    selectedChild: null,
    children: null,
    childrenInClassroom: [],
    expansionPanelsValue: [0],
    isTableLoading: false,
  }),
  methods: {
    async setupPage() {
      const classroomId = this.$route.params.id;
      const resp = await ProductService.getProductById(classroomId);
      this.name = resp.name;
      this.childrenInClassroom = resp.children;
      this.getChildrenList();
      // this.getChildrenTable(classroomId);
    },
    async getChildrenTable(classroomId) {
      this.childrenInClassroom = await ProductService.getChildrenTable(classroomId);
    },
    async getChildrenList() {
      this.children = await ProductService.getChildrenList();
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.disabled = false;
    },
    addChild() {
      this.childrenInClassroom.push(this.selectedChild);
    },
    removeItem(item) {
      const index = this.childrenInClassroom.indexOf(item);
      if (index >= 0) this.childrenInClassroom.splice(index, 1);
    },
    editProduct() {
      const isFormValid = this.$refs.upperForm.validate();
      if (!isFormValid) {
        this.$snotify.error('Por favor, ingresa todos los campos obligatorios', 'Error');
        window.scroll(0, 0);
        return;
      }
      this.$snotify.async('Por favor espere...', 'Creando producto', () => new Promise((resolve, reject) => {
        this.disabled = true;
        ProductService.updateProduct(this.$route.params.id, this.name, this.childrenInClassroom)
          .then(() => {
            this.resetFields();
            resolve({
              title: 'Salon creado',
              body: 'El salon se creó correctamente',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch((error) => {
            this.disabled = false;
            console.log(error);
            reject({
              title: 'Error',
              body: `${error.error}`,
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
  },
  async mounted() {
    this.setupPage();
  },
};
</script>
