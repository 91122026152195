export class AttendanceTableItem {
  constructor(data) {
    this.id = data.id;
    this.attendanceTime = data.attendance_time;
    this.temperature = data.temperature;
    this.form = data.form;
    this.formStatus = data.form.status ? 'Sin problemas/sintomas' : 'Con problemas/sintomas';
  }

  get Owner() {
    if (this.form.child !== null) {
      return this.form.child.firstname;
    }
    return this.form.person_delivers.firstname;
  }
}
