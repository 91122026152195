export class FormTableItem {
  constructor(data) {
    this.id = data.id;
    this.surveyAnswers = data.survey_answers;
    this.formStatus = data.status;
    this.status = data.status ? 'Correcto' : 'Con problemas';
    this.expirationDate = data.expiration_date;
    this.campus = data.campus;
    this.child = data.child;
    this.responsible = data.person_responsible;
    this.personDelivers = data.person_delivers;
  }

  get Owner() {
    if (this.child !== null) {
      return this.child.firstname;
    }
    return this.personDelivers.firstname;
  }
}
