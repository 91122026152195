import axios from 'axios';

const baseUrl = `${process.env.VUE_APP_BASEURL}`;

// function getHeaders() {
//   return TokenUtils.createAuthHeaders();
// }

export default axios.create({
  baseURL: baseUrl,
  headers: { 'Content-Type': 'application/json' },
});
