<template>
  <create-product></create-product>
</template>

<script>
// @ is an alias to /src
import CreateProduct from '@/components/CRUD/CreateProduct2.vue';

export default {
  name: 'home',
  components: {
    CreateProduct,
  },
};
</script>
