<template>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12">
          <h1 class="title-text">Alta de familias</h1>
        </v-col>
      </v-row>
      <v-expansion-panels :value="expansionPanelsValue" multiple>
        <v-expansion-panel value="0" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text"><v-icon color="secondary">mdi-tag</v-icon> Datos generales</p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="upperForm">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="username"
                  outlined label="Nombre de usuario"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="password"
                  :disabled="disabled"
                  :type="showPassword ? 'text' : 'password'"
                  @click:append="showPassword = !showPassword;"
                  :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                  color="secondary" outlined label="Contraseña"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="name"
                  outlined label="Alias"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="childQuantity"
                  outlined label="Cantidad de ninos" type="number"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel value="1" class="mb-4">
          <v-expansion-panel-header>
            <p class="subtitle-text">
              <v-icon color="secondary">mdi-contact-phone</v-icon> Persona responsable
            </p>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-form ref="respForm">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="responsibleFirstname"
                  outlined label="Nombre(s)"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="responsibleLastname"
                  outlined label="Apellidos"></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field :rules="requiredRules" v-model="responsibleContact"
                  outlined label="Contacto"></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row justify="end" class="mt-6">
          <v-btn @click="createClient()" color="secondary">
            <v-icon class="mr-2">mdi-content-save</v-icon> Guardar
          </v-btn>
      </v-row>
    </v-container>
</template>

<script>
import ClientService from '../../../services/ClientService';

export default {
  name: 'create-employee',
  data: () => ({
    username: null,
    password: null,
    name: null,
    childQuantity: null,
    responsibleFirstname: null,
    responsibleLastname: null,
    responsibleContact: null,
    showPassword: false,
    disabled: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
    ],
    expansionPanelsValue: [0, 1],
  }),
  methods: {
    createClient() {
      // validate params
      if (!this.name) {
        this.$swal('Error', 'Ingresa el alias de la familia', 'error');
        return;
      }
      if (!this.username) {
        this.$swal('Error', 'Ingresa el nombre de usuario de la familia', 'error');
        return;
      }
      if (!this.password) {
        this.$swal('Error', 'Ingresa la contrasena de la familia', 'error');
        return;
      }

      this.$snotify.async('Por favor espere..', 'Dando de alta la familia',
        () => new Promise((resolve, reject) => {
          this.disabled = true;

          ClientService.createItem(
            this.username,
            this.password,
            this.name,
            this.childQuantity,
            this.responsibleFirstname,
            this.responsibleLastname,
            this.responsibleContact,
          )
            .then(() => {
              this.resetFields();
              resolve({
                title: 'Familia creada',
                body: 'La familia se dió de alta correctamente',
                config: {
                  timeout: 3000,
                },
              });
            })
            .catch((error) => {
              this.disabled = false;
              reject({
                title: 'Error',
                body: `${error.message}`,
                config: {
                  timeout: 3000,
                },
              });
            });
        }));
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.$refs.respForm.reset();
      this.disabled = false;
    },
  },
};
</script>
