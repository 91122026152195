<template>
<div>
    <v-row>
        <v-col cols="12" sm="12">
            <h1 class="title-text">Tabla de salones</h1>
        </v-col>
    </v-row>
    <v-row>
        <v-col cols="12" sm="12">
            <v-data-table :items="items" :loading="isTableLoading"
            :headers="headers" :search="search" no-data-text="No hay salones">
              <template v-slot:top>
                  <v-spacer></v-spacer>
                  <v-row align="end" justify="end" style="padding-right: 20px;">
                    <v-col cols="12" sm="6">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        label="Buscar por nombre"
                        class="mx-4"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="d-flex justify-end">
                      <v-btn to="/new-product" color="secondary" dark class="mb-2">
                        <v-icon class="mr-2">mdi-plus</v-icon> Alta de salones
                      </v-btn>
                    </v-col>
                  </v-row>
              </template>
              <template v-slot:item.action="{ item }">
                <v-row>
                  <v-col sm="6" cols="12">
                    <v-btn @click="editItem(item)" class="mr-2" outlined color="green">
                      <v-icon
                      small
                      >
                          mdi-pencil
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col sm="6" cols="12">
                    <v-btn @click="removeProduct(item)" outlined color="red">
                      <v-icon
                      small
                      >
                          mdi-delete
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
        </v-col>
    </v-row>
</div>
</template>

<script>
import ProductService from '../../services/ProductService';

export default {
  name: 'products-table',
  data: () => ({
    items: [],
    isTableLoading: true,
    search: '',
    headers: [
      {
        text: 'Id', value: 'id', align: 'center', filterable: false,
      },
      {
        text: 'Nombre', value: 'alias', align: 'center',
      },
      {
        text: 'Ninos', value: 'childrenCount', align: 'center', filterable: false,
      },
      {
        text: 'Acciones', value: 'action', align: 'center',
      },
    ],
    productSearch: null,
    indexItems: [],
  }),
  methods: {
    editItem(item) {
      this.$router.push(`/${item.id}/edit-product`);
    },
    removeProduct(item) {
      this.$swal({
        title: 'Atención',
        text: `¿Estás seguro que quieres eliminar este salon?\n${item.name}`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, eliminar',
      })
        .then((result) => {
          if (result.value) {
            // Llamar servicio de eliminar y quitar elemento de la vista
            this.$snotify.async('Por favor espere...', 'Eliminando Salon', () => new Promise((resolve, reject) => {
              ProductService.deleteProduct(item.id)
                .then(() => {
                  resolve({
                    title: 'Salon eliminado',
                    body: 'Se eliminó el salon',
                    config: {
                      timeout: 3000,
                    },
                  });
                  this.removeProductFromTable(item);
                })
                .catch(() => {
                  reject({
                    title: 'Error',
                    body: 'No se pudo eliminar el salon',
                    consig: {
                      timeout: 3000,
                    },
                  });
                });
            }));
          }
        });
    },
    removeProductFromTable(item) {
      this.items.splice(this.items.indexOf(item), 1);
      this.indexItems.splice(this.items.indexOf(item), 1);
    },
  },
  async mounted() {
    this.isTableLoading = true;
    this.items = await ProductService.getProductsTable();
    for (let i = 0; i < this.items.length; i += 1) {
      this.indexItems.push(this.items[i].id);
    }
    this.isTableLoading = false;
  },
};
</script>
