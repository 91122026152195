export class ClientTableItem {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.childQuantity = data.child_quanity;
    this.authorizedQuantity = data.authorized_quantity;
    this.responsible = data.responsible ? `${data.responsible.firstname} ${data.responsible.lastname}` : 'Sin asignar';
    this.canModify = !data.register_complete;
  }
}
