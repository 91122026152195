export class CampusTableItem {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.shortname = data.shortname;
    this.address = data.address;
    this.startTime = data.start_time;
    this.endTime = data.end_time;
    this.status = data.status;
  }
}
