<template>
  <v-container>
    <v-row>
      <v-col cols='12' sm='12'>
        <h1 class='title-text'>Tabla de asistencia</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :items='items'
          :loading='isTableLoading'
          :headers='headers'
          :search="search"
          no-data-text='No hay asistencias'
        >
          <template v-slot:top>
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  label="Buscar por nombre"
                  class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  :return-value.sync="dateRange"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeText"
                      label="Filtrar por fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dateRange"
                    no-title
                    scrollable
                    range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="dateMenuReset"
                    >
                      Limpiar
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="dateMenuSave"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-end align-center">
                <v-btn small @click="downloadReport()" class="align-self-auto mr-2 pa-4">
                  <v-icon small>
                    mdi-download
                  </v-icon>
                  Descargar reporte
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AttendanceService from '../services/AttendanceService';

export default {
  name: 'attendance-home',
  data: () => ({
    search: '',
    menu: false,
    dateRange: [],
    items: [],
    isTableLoading: false,
    headers: [
      {
        text: 'Id', value: 'id', align: 'center',
      },
      {
        text: 'Nombre', value: 'Owner', align: 'center',
      },
      {
        text: 'Asistencia', value: 'attendanceTime', align: 'center', filterable: false,
      },
      {
        text: 'Temperatura', value: 'temperature', align: 'center',
      },
      {
        text: 'Estatus', value: 'formStatus', align: 'center',
      },
    ],
  }),
  computed: {
    dateRangeText() {
      if (this.dateRange.length === 0) {
        return '';
      }

      if (this.dateRange.length === 1) {
        return this.dateRange[0];
      }

      if (this.dateRange[1] < this.dateRange[0]) {
        [this.dateRange[0], this.dateRange[1]] = [this.dateRange[1], this.dateRange[0]];
      }

      return this.dateRange.join(' - ');
    },
  },
  methods: {
    itemRowBackground(item) {
      return !item.form.formStatus ? 'form-status-alert' : '';
    },
    async getAttendanceTable() {
      const resp = await AttendanceService.getAttendanceTable(this.dateRange);
      this.items = resp;
    },
    dateMenuReset() {
      this.menu = false;
      this.$refs.menu.save('');
      this.dateRange = [];
      this.getAttendanceTable();
    },
    dateMenuSave() {
      this.$refs.menu.save(this.dateRange);
      this.getAttendanceTable();
    },
    downloadReport() {
      AttendanceService.downloadReport(this.dateRange[0], this.dateRange[1]);
    },
  },
  mounted() {
    const yourDate = new Date();
    this.dateRange.push(yourDate.toISOString().split('T')[0]);
    this.getAttendanceTable();
  },
};
</script>
<style>
  .form-status-alert {
    background-color: #f56969;
  }
  .form-status-alert:hover {
    background-color: #f55d5d !important;
  }
  .no-click {
    pointer-events: none;
  }
</style>
