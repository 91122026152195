<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Editar Escuela</h1>
      </v-col>
    </v-row>
    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-tag</v-icon>
            Datos generales
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="upperForm">
            <v-row>
              <v-col cols="12" sm="3">
                <v-select :rules="requiredRules" v-model="selectedClient" :items="clients"
                  item-text="clientName" item-value="id" label="Cliente" disabled></v-select>
              </v-col>
              <v-col cols="12" sm="3" offset-sm="3">
                <v-img
                  max-height="150"
                  max-width="250"
                  :src="url">
                </v-img>
              </v-col>
              <v-col cols="12" sm="3">
                <v-btn @click="selectFile()" color="primary">
                  Subir imagen/logo
                </v-btn>
                <v-file-input
                  ref="inputLogo"
                  v-show="false"
                  v-model="logo"
                  accept="image/*"
                  label="File input"
                  @change="onFileChange"
                ></v-file-input>
                <p class="notes mt-2">*Esta imagen se muestra en el gafete</p>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :rules="requiredRules" v-model="name"
                  color="secondary" outlined label="Nombre"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :rules="requiredRules" v-model="shortname"
                  color="secondary" outlined label="Nombre corto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field :rules="requiredRules" v-model="address"
                  color="secondary" outlined label="Direccion"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field :rules="requiredRules" v-model="latitude"
                  color="secondary" outlined label="Latitud"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field :rules="requiredRules" v-model="longitude"
                  color="secondary" outlined label="Longitud"></v-text-field>
              </v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu"
                  v-model="startTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="startTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :rules="requiredRules"
                      v-model="startTime"
                      label="Hora de entrada"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="24hr"
                    v-if="startTimeMenu"
                    v-model="startTime"
                    full-width
                    @click:minute="$refs.menu.save(startTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="3">
                <v-menu
                  ref="menu2"
                  v-model="endTimeMenu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="endTime"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      outlined
                      :rules="requiredRules"
                      v-model="endTime"
                      label="Hora de salida"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    format="24hr"
                    v-if="endTimeMenu"
                    v-model="endTime"
                    full-width
                    @click:minute="$refs.menu2.save(endTime)"
                  ></v-time-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="3">
                <v-text-field :rules="requiredRules" v-model="formDuration"
                  color="secondary" outlined label="Duracion del formulario (en horas)"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-checkbox v-model="status" color="secondary"
                  outlined label="Activa"></v-checkbox>
              </v-col>
              <v-col cols="6" v-show="usesTelpo">
                <v-btn @click="config()" color="primary" dark class="mb-2 align-right">
                  <v-icon class="mr-2">mdi-cog</v-icon> Configuracion del dispositivo
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" class="mt-6">
      <v-btn @click="save()" color="secondary">
        <v-icon class="mr-2">mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import SystemClientService from '../../services/SystemClientService';
import CampusService from '../../services/CampusService';

export default {
  name: 'edit-campus',
  data: () => ({
    campusId: 0,
    data: null,
    clients: [],
    name: null,
    shortname: null,
    address: null,
    latitude: null,
    longitude: null,
    startTime: '06:00',
    endTime: '13:00',
    formDuration: '02:00',
    selectedClient: null,
    startTimeMenu: false,
    endTimeMenu: false,
    usesTelpo: false,
    status: false,
    logo: null,
    url: '',
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
      v => v !== ' ' || 'Este campo es obligatorio',
    ],
    expansionPanelsValue: [0],
  }),
  methods: {
    async getClientList() {
      this.clients = await SystemClientService.getList();
    },
    selectFile() {
      this.$refs.inputLogo.$refs.input.click();
    },
    onFileChange() {
      this.url = URL.createObjectURL(this.logo);
      this.uploadImage();
    },
    config() {
      this.$router.push(`/${this.campusId}/campus-config`);
    },
    async loadCampusData() {
      const data = await CampusService.getCampusData(this.campusId);
      this.name = data.name;
      this.shortname = data.shortname;
      this.address = data.address;
      this.latitude = data.latitude;
      this.longitude = data.longitude;
      this.startTime = data.start_time;
      this.endTime = data.end_time;
      this.formDuration = data.form_duration;
      this.status = data.status;
      this.usesTelpo = data.uses_telpo;
      this.selectedClient = data.client.id;
      this.url = data.logo;
    },
    resetFields() {
      this.$refs.upperForm.reset();
    },
    uploadImage() {
      const campusId = this.$route.params.id;
      this.$snotify.async('Por favor espere...', 'Actualizando imagen', () => new Promise((resolve, reject) => {
        this.disabled = true;
        CampusService.updateLogo(campusId, this.logo)
          .then(() => {
            resolve({
              title: 'Imagen actualizada',
              body: 'La imagen se actualizó correctamente',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch((error) => {
            this.disabled = false;
            console.log(error);
            reject({
              title: 'Error',
              body: `${error.error}`,
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
    save() {
      const campusId = this.$route.params.id;

      const isFormValid = this.$refs.upperForm.validate();
      if (!isFormValid) {
        this.$snotify.error('Por favor, ingresa todos los campos obligatorios', 'Error');
        window.scroll(0, 0);
        return;
      }
      this.$sotify.async('Por favor espere...', 'Editando escuela', () => new Promise((resolve, reject) => {
        this.disabled = true;
        CampusService.updateItem(campusId, this.name, this.shortname, this.address,
          this.latitude, this.longitude, this.startTime, this.endTime, this.formDuration,
          this.status)
          .then(() => {
            // this.resetFields();
            resolve({
              title: 'Escuela actualizada',
              body: 'La escuela se actualizó correctamente',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch((error) => {
            this.disabled = false;
            console.log(error);
            reject({
              title: 'Error',
              body: `${error.error}`,
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
  },
  mounted() {
    this.campusId = this.$route.params.id;
    this.loadCampusData();
    this.getClientList();
  },
};
</script>

<style>
.notes {
  color: red;
  font-size: 10pt;
}
</style>
