<template>
  <v-row>
    <v-col cols="12" sm="5">
      <v-row align="center" justify="center"></v-row>
      <v-col cols="12" sm="12">
        <v-img src="../assets/celularingress.png" alt="" contain height="400"></v-img>
      </v-col>
    </v-col>
    <v-col cols="12" sm="7">
      <v-row align="center" justify="center">
        <v-col cols="6" sm="12" md="8">
          <v-card class="elevation-12">
            <!-- LOGIN CARD TOOLBAR -->
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>
                <v-icon>mdi-login</v-icon>
                Inicio de sesión
              </v-toolbar-title>
              <v-spacer/>
            </v-toolbar>
            <!-- LOGIN CARD TOOLBAR -->

            <!-- LOGIN INPUT FORM -->
            <v-card-text>
              <v-form>
                <v-text-field outlined v-model="username" label="Usuario"
                              color="secondary"
                              name="login" prepend-icon="mdi-account" type="text"/>

                <v-text-field outlined v-model="password" id="password"
                              label="Contraseña" color="secondary"
                              name="password" prepend-icon="mdi-lock" type="password"
                              @keypress.enter="authUser"/>
              </v-form>
            </v-card-text>
            <!-- LOGIN INPUT FORM -->

            <!-- LOGIN BUTTONS -->
            <v-card-actions>
              <v-spacer/>
              <v-btn @click="authUser()" color="secondary" class="login-btn">Entrar</v-btn>
            </v-card-actions>
            <!-- LOGIN BUTTONS -->

          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import AuthService from '../services/AuthService';
import { TokenUtils } from '../utils/TokenUtils';

export default {
  name: 'login-card',
  props: {
    source: String,
  },
  data: () => ({
    username: '',
    password: '',
  }),
  methods: {
    async authUser() {
      if (!this.username) {
        this.$swal('Error', 'Ingresa el usuario', 'error');
        return;
      }
      if (!this.password) {
        this.$swal('Error', 'Ingresa la contraseña', 'error');
        return;
      }

      const resp = await AuthService.auth(this.username, this.password);
      if (resp.error) {
        this.$swal('Error', `No se puede iniciar sesión, ${resp.error}`, 'error');
        return;
      }
      TokenUtils.setToken(resp.token);
      // const payload = TokenUtils.getJwtData();
      // this.$store.dispatch('setAuthUserData', payload);
      this.$router.push('/dashboard');
    },
  },
};
</script>


<style>
.admin-text {
  z-index: 1;
}

h2 {
  letter-spacing: 0.25rem;
  color: white;
  font-size: 2rem;
  font-weight: 300;
}
.login-btn > span {
  color: white;
}
</style>
