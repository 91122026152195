import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { ClientTableItem } from '../common/models/ClientTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = '/system/family';

export default {
  async createItem(username, password, alias, childQuantity,
    responsibleFirstname, responsibleLastname, responsibleContact) {
    const responsible = {
      firstname: responsibleFirstname,
      lastname: responsibleLastname,
      person_type: 1,
    };
    const payload = {
      username,
      password,
      alias,
      childQuantity,
      responsible,
      responsibleContact,
    };
    let response = null;
    try {
      response = await Axios.post(`${resource}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (error) {
      response = new ErrorResponse(error.response.data);
      throw response;
    }
    return response;
  },

  async getTable() {
    const response = [];
    let resp = null;
    resp = await Axios.get(`${resource}/table`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    resp.data.data.forEach((item) => {
      const tableItem = new ClientTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async getList() {
    const response = [];
    let resp = null;
    resp = await Axios.get(`${resource}/list`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    resp.data.data.forEach((item) => {
      const tableItem = new ClientTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async deleteItem(id) {
    let response = null;
    try {
      response = await Axios.delete(`${resource}/${id}`,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getItemById(id) {
    let response = null;
    const data = await Axios.get(`${resource}/${id}`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    response = data.data.data;
    return response;
  },

  async updateItem(id, name, contactInfo) {
    const payload = {
      name,
      contactInfo,
    };
    let response = null;
    try {
      response = await Axios.put(`${resource}/${id}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async allowUpdates(familyId, value) {
    const payload = {
      value,
    };
    let response = null;
    try {
      response = await Axios.put(`${resource}/allow-updates/${familyId}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async remakeBadges(familyId) {
    let response = null;
    try {
      response = await Axios.put(`${resource}/${familyId}/badge/remake`, {},
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async downloadBadges(familyId) {
    let response = null;
    try {
      response = await Axios.get(`${resource}/${familyId}/badges`,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async resendMail(familyId) {
    let response = null;
    try {
      response = await Axios.post(`${resource}/${familyId}/mail`, {},
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },
  async downloadReport() {
    const data = await Axios({
      method: 'get',
      url: `${resource}/report`,
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${TokenUtils.getToken()}`,
      },
    });
    this.forceFileDownload(data, 'reporte_familias.xlsx');
  },
  forceFileDownload(response, filename) {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
  },
};
