import CurrencyFormatter from './CurrencyFormatter';
import { SupplierPurchaseOrderStatuses } from './consts';

export class SupplierPurchasOrderData {
  constructor(id, supplierId, total, createdDt, products, status) {
    this.id = id;
    this.supplierId = supplierId;
    this.total = total;
    this.createdDt = createdDt;
    this.products = [];
    this.status = status;

    const isOpen = status === SupplierPurchaseOrderStatuses.OPENED;
    products.forEach((x) => {
      this.products.push({
        isOk: isOpen || x.alreadyCounted === 1,
        rowId: x.id,
        id: x.product.id,
        name: x.product.name,
        quantity: x.quantity,
        receivedQuantity: x.receivedQuantity ? x.receivedQuantity : x.quantity,
        model: x.product.model,
        saleCostStr: CurrencyFormatter.format(x.product.baseCost),
        unitCost: x.unitCost,
        buyCostStr: CurrencyFormatter.format(x.unitCost),
        totalCost: x.quantity * x.unitCost,
        totalCostStr: CurrencyFormatter.format(x.quantity * x.unitCost),
        measuringUnit: `${x.product.measuringUnit.label}-${x.product.measuringUnit.satName}`,
        alreadyCounted: x.alreadyCounted ? x.alreadyCounted : 0,
      });
    });
  }
}
