<template>
<v-container>
  <employees-table></employees-table>
</v-container>
</template>

<script>
// @ is an alias to /src
import EmployeesTable from '../components/EmployeesTable.vue';

export default {
  name: 'employees-home',
  components: {
    EmployeesTable,
  },
};
</script>
