<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Editar Nino</h1>
      </v-col>
    </v-row>
    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-tag</v-icon>
            Datos generales
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-form ref="upperForm">
            <v-row>
              <v-col cols="12" sm="4">
                <v-select :rules="requiredRules" v-model="selectedFamily" :items="families"
                  item-text="name" item-value="id" label="Familia" disabled></v-select>
              </v-col>
              <v-col sm="8"></v-col>
              <v-col cols="12" sm="6">
                <v-text-field :rules="requiredRules" v-model="registerNumber" :disabled="disabled"
                  color="secondary" outlined label="Matricula"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field :rules="requiredRules" v-model="name" :disabled="disabled"
                  color="secondary" outlined label="Nombre"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" class="mt-6">
      <v-btn @click="updateChild()" color="secondary">
        <v-icon class="mr-2">mdi-content-save</v-icon>
        Guardar
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import ClientService from '../../services/ClientService';
import TagService from '../../services/TagService';

export default {
  name: 'edit-children',
  data: () => ({
    disabled: false,
    requiredRules: [
      v => !!v || 'Este campo es obligatorio',
      v => v !== ' ' || 'Este campo es obligatorio',
    ],
    name: null,
    registerNumber: null,
    selectedFamily: null,
    families: [],
    expansionPanelsValue: [0],
    isTableLoading: false,
  }),
  methods: {
    async getChildData() {
      const childId = this.$route.params.id;
      const childData = await TagService.getItemById(childId);
      this.name = childData.firstname;
      this.registerNumber = childData.registerNumber;
      this.selectedFamily = childData.family;
    },
    async getFamiliesList() {
      this.families = await ClientService.getList();
    },
    resetFields() {
      this.$refs.upperForm.reset();
      this.disabled = false;
    },
    updateChild() {
      const childId = this.$route.params.id;
      const isFormValid = this.$refs.upperForm.validate();
      if (!isFormValid) {
        this.$snotify.error('Por favor, ingresa todos los campos obligatorios', 'Error');
        window.scroll(0, 0);
        return;
      }
      this.$snotify.async('Por favor espere...', 'Registrando nino', () => new Promise((resolve, reject) => {
        this.disabled = true;
        TagService.updateItem(childId, this.registerNumber, this.name, this.selectedFamily.id)
          .then(() => {
            this.resetFields();
            resolve({
              title: 'Nino registrado',
              body: 'El nino se registró correctamente',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch((error) => {
            this.disabled = false;
            console.log(error);
            reject({
              title: 'Error',
              body: `${error.error}`,
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
  },

  async mounted() {
    this.getFamiliesList();
    this.getChildData();
  },
  created() {
  },
  destroyed() {
  },
};
</script>
