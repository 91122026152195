import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { ProductTableItem } from '../common/models/ProductTableItem';
import { ProductRequestData } from '../common/models/ProductRequestData';
import { ChildTableItem } from '../common/models/ChildTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = '/system/classroom';

export default {
  async createProduct(alias, children) {
    const payload = new ProductRequestData(alias, JSON.stringify(children));
    let response = null;
    try {
      response = await Axios.post(`${resource}`, payload,
        { headers: { Authorization: `Bearer  ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e.response);
      response = new ErrorResponse(e.response.data);
      throw response;
    }
    return response;
  },

  async uploadFile(id, file) {
    let response = null;
    const payload = new FormData();
    payload.append('file', file);
    try {
      response = await Axios.post(`${resource}/${id}/file`, payload);
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getProductsTable() {
    const response = [];
    let data = null;
    data = await Axios.get(`${resource}/table`, { headers: { Authorization: `Bearer  ${TokenUtils.getToken()}` } });
    data.data.data.forEach((item) => {
      const tableItem = new ProductTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async getChildrenTable(classroomId) {
    const response = [];
    const payload = new FormData();
    payload.append('classroom_id', classroomId);
    let data = null;
    data = await Axios.get('system/children/table', payload);
    data.data.data.forEach((item) => {
      const tableItem = new ChildTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async getChildrenList() {
    const response = [];
    const config = {
      params: {
        classroom_id: -1,
      },
      headers: { Authorization: `Bearer  ${TokenUtils.getToken()}` },
    };

    let data = null;
    data = await Axios.get('system/children/list', config);

    data.data.data.forEach((item) => {
      const tableItem = new ChildTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async deleteProduct(id) {
    let response = null;
    try {
      response = await Axios.delete(`${resource}/${id}`,
        { headers: { Authorization: `Bearer  ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },

  async getProductById(id) {
    let response = null;
    const data = await Axios.get(`${resource}/${id}`,
      { headers: { Authorization: `Bearer  ${TokenUtils.getToken()}` } });
    response = data.data.data;
    return response;
  },

  async updateProduct(id, alias, children) {
    const payload = new ProductRequestData(alias, JSON.stringify(children));
    let response = null;
    try {
      response = await Axios.put(`${resource}/${id}`, payload,
        { headers: { Authorization: `Bearer  ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e);
      response = new ErrorResponse(e);
      throw response;
    }
    return response;
  },
};
