<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">
          <v-icon color="secondary">mdi-tag</v-icon>
          Orden de compra
        </h1>
      </v-col>
    </v-row>

    <v-expansion-panels :value="expansionPanelsValue" multiple>
      <v-expansion-panel value="0" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-magnify</v-icon>
            Buscar orden de compra
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col>
              <v-autocomplete hide-details outlined
                              :disabled="disableSelectStore"
                              v-model="selectedStore"
                              @change="storeChanged"
                              no-data-text="No encontrado..."
                              :items="stores"
                              item-text="label"
                              item-value="value"
                              color="secondary"
                              label="Sucursal">
              </v-autocomplete>
            </v-col>
            <v-col></v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-autocomplete hide-details outlined
                              v-model="searchSupplier"
                              @change="supplierSearchChanged"
                              no-data-text="No hay proveedores"
                              :items="suppliers" item-text="label" item-value="value"
                              color="secondary" label="Proveedor"></v-autocomplete>
            </v-col>
            <v-col>
              <v-autocomplete hide-details outlined
                              v-model="searchSPO"
                              @change="supplierPurchaseOrderSearchChanged"
                              no-data-text="No hay órdenes"
                              :items="supplierOrders" item-text="label" item-value="id"
                              color="secondary" label="Órdenes de compra"></v-autocomplete>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel value="1" class="mb-4">
        <v-expansion-panel-header>
          <p class="subtitle-text">
            <v-icon color="secondary">mdi-plus</v-icon>
            Agregar producto
          </p>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row>
            <v-col cols="12" sm="3">
              <v-autocomplete hide-details outlined
                              :disabled="disableSupplier"
                              v-model="selectedSupplier" @change="supplierChanged"
                              no-data-text="No hay proveedores"
                              :items="suppliers" item-text="label" item-value="value"
                              color="secondary" label="Proveedor"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6">
              <v-autocomplete hide-details outlined
                              @change="selectedProductChanged"
                              v-model="selectedProduct"
                              no-data-text="No hay productos"
                              :items="products" item-text="name" item-value="id" :return-object="true"
                              color="secondary" label="Producto"></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="2">
              <v-currency-field hide-details outlined
                                v-model="unitCost" :disabled="unitCostDisabled"
                                color="secondary" label="Costo unitario"></v-currency-field>
            </v-col>
            <v-col align-self="center">
              <v-btn :disabled="status === 2"
                     @click="addItem" color="secondary">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-row justify="end" align="end">
      <h2 class="mr-4 mt-2 order-total">Total de la orden: <b>{{ purchaseOrderTotal }}</b></h2>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12">
        <v-data-table :items="items"
                      no-data-text="No has agregado productos a la órden"
                      :headers="headers" class="elevation-1">
          <template v-slot:top>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">Editar registro</span>
                </v-card-title>

                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12">
                        <v-text-field color="secondary" :disabled="disabled"
                                      outlined v-model="editQuantity" label="Cantidad"></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-currency-field color="secondary" :disabled="disabled"
                                          outlined v-model="editCost" label="Costo unitario">
                        </v-currency-field>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="red" dark @click="dialog = false;">Cancelar</v-btn>
                  <v-btn color="secondary darken-1" dark @click="saveItem">Guardar</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
          <template v-slot:item.receivedQuantity="{ item, value }">
            <span v-if="!item.alreadyCounted">N/A</span>
            <span v-else>{{ value }}</span>
          </template>
          <template v-slot:item.action="{ item }">
            <v-row v-if="!status || (status === 1 && !inventoryAffected)">
              <v-col sm="5">
                <v-btn small @click="setupEdit(item)" class="mr-2" outlined color="green">
                  <v-icon small>
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col sm="5">
                <v-btn small @click="removeItem(item)" outlined color="red">
                  <v-icon small>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-else-if="!!status">
              <span v-if="item.alreadyCounted === 1" class="font-weight-bold">PROCESADO</span>
              <span v-else-if="status === 1 && item.alreadyCounted !== 1">PENDIENTE</span>
              <span v-else-if="item.alreadyCounted !== 1">DESCARTADO</span>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row v-if="items.length > 0" justify="end" align="end">
      <v-btn v-if="supplierPurchaseOrderId && status && status === 1" class="mr-4"
             @click="cancelSupplierPurchaseOrder"
             color="red" dark>
        <v-icon>mdi-close</v-icon>
        Eliminar
      </v-btn>
      <v-btn v-if="!status || status === 1" @click="saveChanges" class="mr-4" color="secondary">
        <v-icon>mdi-save</v-icon>
        Guardar
      </v-btn>
      <v-btn v-if="supplierPurchaseOrderId && status && status === 1"
             @click="closeSupplierPurchaseOrder"
             color="info" class="mr-4">Terminar
      </v-btn>
      <v-btn dark v-if="(supplierPurchaseOrderId && status && (status === 1 || status === 2))"
             @click="startNewPurchaseOrder"
             color="success">Generar nueva orden de compra
      </v-btn>
    </v-row>
    <img class="hidden" id="test" v-bind:src="img" alt="">
  </v-container>
</template>

<script>
import SupplierService from '../services/SupplierService';
import ProductService from '../services/ProductService';
import InventoryService from '../services/InventoryService';
import CurrencyFormatter from '../common/models/CurrencyFormatter';
import { SupplierPurchaseOrderStatuses } from '../common/models/consts';
import { TokenUtils } from '../utils/TokenUtils';
import UserService from '../services/UserService';

const img = require('../assets/pdf-template.jpg');

export default {
  name: 'category-home',
  computed: {
    formTitle() {
      return this.id ? 'Editar Etiqueta' : 'Crear Etiqueta';
    },
    selectedItem() {
      return this.items.find(x => x.id === this.id);
    },
    validRegisters() {
      return this.items.filter(x => x.id > 0);
    },
    purchaseOrderTotal() {
      let total = 0;
      this.items.forEach((x) => {
        total += x.totalCost;
      });
      total = CurrencyFormatter.format(total);
      return total;
    },
    headers() {
      const columns = [
        { text: 'Cantidad', value: 'quantity', align: 'center' },
        { text: 'Producto', value: 'name', align: 'center' },
        { text: 'Modelo', value: 'model', align: 'center' },
        { text: 'Unidad de medida', value: 'measuringUnit', align: 'center' },
        { text: 'Precio de venta', value: 'saleCostStr', align: 'center' },
        { text: 'Costo unitario', value: 'buyCostStr', align: 'center' },
        { text: 'Costo Total', value: 'totalCostStr', align: 'center' },
        { text: 'Acciones', value: 'action', align: 'center' },
      ];

      const receivedQuantityColumn = { text: 'Recibido', value: 'receivedQuantity', align: 'center' };
      // show if
      // - some item has already been entered to inventory
      // - the PO status is closed
      // - the PO has already been created (status not null)
      if ((this.status !== SupplierPurchaseOrderStatuses.OPENED || this.inventoryAffected) && this.status !== null) {
        columns.splice(1, 0, receivedQuantityColumn);
      }
      return columns;
    },
  },
  data: () => ({
    expansionPanelsValue: [0, 1],
    items: [],
    isTableLoading: false,
    formattedPurchaseOrderItems: [{ quantity: 1, name: 'test', model: 'a' }],
    dialog: null,
    name: null,
    editQuantity: null,
    editCost: null,
    disabled: false,
    parentTag: -1,
    editItem: { quantity: 0, unitCost: 0 },
    suppliers: null,
    products: [],
    realItemsList: [],
    menu: null,
    createdDt: null,
    selectedSupplier: null,
    selectedProduct: null,
    unitCost: null,
    disableSupplier: false,
    searchSupplier: null,
    unitCostDisabled: false,
    supplierPurchaseOrderId: null,
    supplierOrders: [],
    searchSPO: null,
    inventoryAffected: false,
    status: null,
    pageWidth: 595,
    pageHeight: 842,
    horizontalMargin: 20,

    cellPadding: 10,
    cellWidth: 180,
    cellHeight: 20,
    lineHeight: 20,

    startX: null,
    startY: null,

    doc: null,
    page: 1,
    imageData: null,
    img,
    topMargin: 150,
    bottomMargin: 0,

    stores: [],
    selectedStore: null,
    disableSelectStore: false,
  }),
  methods: {
    async supplierChanged() {
      this.realItemsList = [];
      this.products = await ProductService.getProductsBySupplierId(this.selectedSupplier);
    },
    selectedProductChanged() {
      if (this.items.length > 0) {
        const item = this.items.find(x => x.id === this.selectedProduct.id);
        if (item) {
          this.unitCost = item.unitCost;
          this.unitCostDisabled = true;
          return;
        }
      }
      this.unitCost = null;
      this.unitCostDisabled = false;
    },
    addItem() {
      if (!this.selectedSupplier || !this.selectedProduct || !this.unitCost || this.unitCost <= 0) {
        this.$swal(
          'Error',
          'Por favor llena todos los campos antes de continuar. (Proveedor, Producto y Costo unitario',
          'error',
        );
        return;
      }

      // check if editing an order
      if (!!this.supplierPurchaseOrderId) {
        if (this.status !== SupplierPurchaseOrderStatuses.OPENED || this.inventoryAffected) {
          this.$swal('Atención', 'No se deben editar órdenes que ya han afectado el inventario.', 'warning');
          return;
        }
      }

      this.disableSupplier = true;
      const itemAlreadyInList = this.items.find(x => x.id === this.selectedProduct.id);
      if (itemAlreadyInList) {
        itemAlreadyInList.quantity += 1;
        itemAlreadyInList.totalCost += itemAlreadyInList.unitCost;
        itemAlreadyInList.totalCostStr = itemAlreadyInList.totalCost;
      } else {
        this.items.push({
          id: this.selectedProduct.id,
          name: this.selectedProduct.name,
          quantity: 1,
          model: this.selectedProduct.model,
          measuringUnit: this.selectedProduct.measuringUnit,
          saleCostStr: this.selectedProduct.baseCostStr,
          unitCost: this.unitCost,
          buyCostStr: CurrencyFormatter.format(this.unitCost),
          totalCost: this.unitCost,
          totalCostStr: CurrencyFormatter.format(this.unitCost),
        });
      }
      this.unitCost = 0;
      this.unitCostDisabled = false;
      this.selectedProduct = null;
    },
    saveItem() {
      this.editItem.quantity = this.editQuantity;
      this.editItem.unitCost = this.editCost;
      this.editItem.totalCost = this.editItem.unitCost * this.editItem.quantity;
      this.editItem.buyCostStr = CurrencyFormatter.format(this.editItem.unitCost);
      this.editItem.totalCostStr = CurrencyFormatter.format(this.editItem.totalCost);
      this.dialog = false;
    },
    resetFields() {
      this.name = null;
      this.id = null;
      this.parentTagId = -1;
    },
    setupEdit(item) {
      this.editItem = item;
      this.editQuantity = item.quantity;
      this.editCost = item.unitCost;
      this.dialog = true;
    },
    removeItem(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    saveChanges() {
      if (this.supplierPurchaseOrderId && this.supplierPurchaseOrderId > 0) {
        if (!this.inventoryAffected) {
          this.updateSupplierPurchaseOrder();
        } else {
          this.$swal('Atención', 'No se deben editar órdenes que ya han afectado el inventario.', 'warning');
        }
      } else {
        this.saveSupplierPurchaseOrder();
      }
    },
    async saveSupplierPurchaseOrder() {
      let total = 0;
      const formattedItems = [];
      this.items.forEach((x) => {
        total += x.totalCost;
        formattedItems.push({ productId: x.id, unitCost: x.unitCost, quantity: x.quantity });
      });
      const resp = await InventoryService.saveSupplierPurchaseOrder(
        this.selectedStore,
        this.selectedSupplier,
        total,
        formattedItems,
      );
      if (resp.error) {
        this.$swal('Error', resp.error, 'error');
        this.clearFields();
        return;
      }
      this.clearFields();
      this.supplierPurchaseOrderId = null;
      this.searchSPO = null;
      this.supplierSearchChanged();
      this.$swal('Correcto', 'Se creó la orden de compra a proveedor', 'success');
    },
    async updateSupplierPurchaseOrder() {
      let total = 0;
      const formattedItems = [];
      this.items.forEach((x) => {
        total += x.totalCost;
        formattedItems.push({ productId: x.id, unitCost: x.unitCost, quantity: x.quantity });
      });
      const resp = await InventoryService.updateSupplierPurchaseOrder(
        this.supplierPurchaseOrderId,
        total,
        formattedItems,
      );
      if (resp.error) {
        this.$swal('Error', resp.error, 'error');
        this.clearFields();
        return;
      }
      this.clearFields();
      this.supplierPurchaseOrderId = null;
      this.searchSPO = null;
      this.$swal('Correcto', 'Se actualizó la órden de compra a proveedor', 'success');
    },
    async cancelSupplierPurchaseOrder() {
      if (this.status !== SupplierPurchaseOrderStatuses.OPENED || this.inventoryAffected) {
        this.$swal('Atención', 'No es posible cancelar una orden parcialmente afectada.', 'warning');
        return;
      }
      const resp = await InventoryService.cancelSupplierPurchaseOrder(this.supplierPurchaseOrderId);
      if (resp.error) {
        this.$swal('Error', resp.error, 'error');
        this.clearFields();
        return;
      }
      this.clearFields();
      this.supplierPurchaseOrderId = null;
      this.searchSPO = null;
      this.supplierSearchChanged();
      this.$swal('Correcto', 'Se canceló la órden de compra a proveedor', 'success');
    },
    async closeSupplierPurchaseOrder() {
      const resp = await InventoryService.closeSupplierPurchaseOrder(this.supplierPurchaseOrderId);
      if (resp.error) {
        this.$swal('Error', resp.error, 'error');
        return;
      }

      this.clearFields();
      this.supplierPurchaseOrderId = null;
      this.searchSPO = null;
      this.supplierSearchChanged();
      this.$swal('Correcto', 'Se cerró la órden de compra a proveedor', 'success');
    },
    clearFields() {
      this.items = [];
      this.selectedSupplier = null;
      this.selectedProduct = null;
      this.unitCost = null;
      this.disableSupplier = false;
      this.disableAddBtn = false;
      this.status = null;
    },
    clearFieldsNotItems() {
      this.selectedProduct = null;
      this.unitCost = null;
    },
    startNewPurchaseOrder() {
      this.clearFields();
      this.supplierPurchaseOrderId = null;
      this.searchSPO = null;
    },
    async supplierPurchaseOrderSearchChanged() {
      const data = await InventoryService.getSupplierPurchaseOrderData(this.searchSPO);
      this.selectedSupplier = data.supplierId;
      this.items = data.products;
      this.disableSupplier = true;
      this.supplierPurchaseOrderId = data.id;
      this.status = data.status;
      this.createdDt = null;

      // cant cancel a PO if inventory affected
      // cant edit a PO if inventory affected
      this.inventoryAffected = data.products.map(x => x.alreadyCounted).includes(1);

      this.products = await ProductService.getProductsBySupplierId(this.selectedSupplier);
    },
    async supplierSearchChanged() {
      this.supplierOrders = await InventoryService.getSupplierPurchaseOrderList(
        this.searchSupplier,
        this.selectedStore,
      );
      this.clearFields();
    },
    async storeChanged() {
      this.searchSupplier = null;
      this.supplierOrders = [];
      this.clearFields();
    },
  },
  async mounted() {
    const payload = TokenUtils.getJwtData();

    // eslint-disable-next-line camelcase
    const { user_claims: userClaims, identity: userId } = payload;
    const { store } = userClaims;

    this.suppliers = await SupplierService.getTable();
    this.stores = await UserService.getUserStores(userId);

    this.disableSelectStore = !!store && store.length === 1;

    // pre-select the first store or the user store if available
    if (this.disableSelectStore && !!this.stores) {
      [this.selectedStore] = store;
    } else if (!!this.stores) {
      this.selectedStore = this.stores[0].value;
    }
  },
};
</script>

<style scoped>
.hidden {
  display: none;
}

.order-total {
  font-weight: 400;
}
</style>
