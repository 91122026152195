export class SupplierPurchasOrderList {
  constructor(id, createdDt, status, products = []) {
    this.id = id;
    this.createdDt = createdDt;
    this.status = status;
    this.products = products;
  }

  get label() {
    let dt = this.createdDt.split('T')[0];
    dt = dt.split('-');
    const formattedDt = `${dt[2]}/${dt[1]}/${dt[0]}`;
    if (this.status === 1) {
      return `${formattedDt} - (ABIERTA)`;
    }
    if (this.status === 2) {
      return `${formattedDt} - (CERRADA)`;
    }
    if (this.status === 3) {
      return `${formattedDt} - (INVENTARIO REGISTRADO)`;
    }
    return '';
  }

  get inventoryLabel() {
    let dt = this.createdDt.split('T')[0];
    dt = dt.split('-');
    const totalProducts = this.products.length;
    const alreadyCountedProducts = this.products.filter(x => x.alreadyCounted === 1).length;
    const formattedDt = `${dt[2]}/${dt[1]}/${dt[0]}`;
    if (this.status === 1) {
      return `${formattedDt} - (ABIERTA) ${alreadyCountedProducts}/${totalProducts}`;
    }
    if (this.status === 2) {
      return `${formattedDt} - (CERRADA) ${alreadyCountedProducts}/${totalProducts}`;
    }
    if (this.status === 3) {
      return `${formattedDt} - (INVENTARIO REGISTRADO)`;
    }
    return '';
  }
}
