import Axios from '@/services/Axios';
import { SystemClientTableItem } from '../common/models/SystemClientTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = '/system/client';

export default {
  async getList() {
    const response = [];
    let resp = null;
    const config = {
      headers: { Authorization: `Bearer ${TokenUtils.getToken()}` },
    };
    resp = await Axios.get(`${resource}/list`, config);

    resp.data.data.forEach((item) => {
      const tableItem = new SystemClientTableItem(item);
      response.push(tableItem);
    });
    return response;
  },
};
