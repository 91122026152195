export class DiscountSpecialTableItem {
  constructor(data) {
    this.id = data.id;
    this.discount = data.discount;
    this.product = data.product;
    this.startDt = data.startDt;
    this.endDt = data.endDt;
  }

  get productName() {
    return this.product.name;
  }
}
