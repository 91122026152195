import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { CampusTableItem } from '../common/models/CampusTableItem';
import { TokenUtils } from '../utils/TokenUtils';

const resource = '/system/campus';

export default {
  async getCampusData(id) {
    let response = null;
    const data = await Axios.get(`${resource}/${id}`,
      { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    response = data.data.data;
    return response;
  },

  async getTable() {
    const response = [];
    let resp = null;
    const config = {
      headers: { Authorization: `Bearer ${TokenUtils.getToken()}` },
    };
    resp = await Axios.get(`${resource}/table`, config);

    resp.data.data.forEach((item) => {
      const tableItem = new CampusTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async getList() {
    const response = [];
    let resp = null;
    const config = {
      headers: { Authorization: `Bearer ${TokenUtils.getToken()}` },
    };
    resp = await Axios.get(`${resource}/list`, config);

    resp.data.data.forEach((item) => {
      const tableItem = new CampusTableItem(item);
      response.push(tableItem);
    });
    return response;
  },

  async createItem(clientId, name, shortname, address,
    latitude, longitude, startTime, endTime, formDuration) {
    const payload = {
      client_id: clientId,
      campus_name: name,
      shortname,
      campus_address: address,
      latitude,
      longitude,
      start_time: startTime,
      end_time: endTime,
      form_duration: formDuration,
    };
    let response = null;
    try {
      response = await Axios.post(`${resource}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (error) {
      response = new ErrorResponse(error.response.data);
      throw response;
    }
    return response;
  },

  async updateItem(campusId, name, shortname, address,
    latitude, longitude, startTime, endTime, formDuration, status) {
    const payload = {
      campus_name: name,
      shortname,
      campus_address: address,
      latitude,
      longitude,
      start_time: startTime,
      end_time: endTime,
      form_duration: formDuration,
      status,
    };

    let response = null;
    try {
      response = await Axios.put(`${resource}/${campusId}`, payload,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (error) {
      response = new ErrorResponse(error.response.data);
      throw response;
    }
    return response;
  },

  async updateLogo(campusId, file) {
    const formData = new FormData();
    formData.append('logo', file);
    let response = null;
    try {
      response = await Axios.put(`${resource}/${campusId}/image`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      console.error(error);
      // response = ErrorResponse(error.response.data);
      throw response;
    }
    return response;
  },

  async loadCampusConfig(campusId) {
    let response = null;
    try {
      response = await Axios.get(`${resource}/${campusId}/config`,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (error) {
      response = new ErrorResponse(error.response.data);
      throw response;
    }
    return response;
  },

  async saveCampusConfig(campusId, data) {
    let response = null;
    try {
      response = await Axios.put(`${resource}/${campusId}/config`, data,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (error) {
      response = new ErrorResponse(error.response.data);
      throw response;
    }
    return response;
  },
};
