<template>
<v-container>
  <products-table></products-table>
</v-container>
</template>

<script>
// @ is an alias to /src
import ProductsTable from '@/components/dashboard/ProductsTable.vue';

export default {
  name: 'home',
  components: {
    ProductsTable,
  },
};
</script>
