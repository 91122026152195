<template>
<div>
    <v-row>
        <v-col cols="12" sm="12">
            <h1 class="title-text">Tabla de familias</h1>
        </v-col>
    </v-row>

    <v-row>
        <v-col cols="12" sm="12">
            <v-data-table :items="items" :loading="isTableLoading"
            :headers="headers" :search="search" no-data-text="No hay familias">
                <template v-slot:top>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col cols="12" sm="6">
                        <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        single-line
                        hide-details
                        label="Buscar"
                        class="mx-4"
                      ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" class="d-flex justify-end">
                        <v-btn to="/new-client" color="secondary" dark class="mr-2 mb-2">
                          <v-icon class="mr-2">mdi-plus</v-icon> Alta de familias
                        </v-btn>
                        <v-btn small @click="downloadReport()" class="align-self-auto mr-2 pa-4">
                          <v-icon small>
                            mdi-download
                          </v-icon>
                          Descargar reporte
                        </v-btn>
                      </v-col>
                    </v-row>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-row>
                    <v-col sm="3" offset="1" class="d-flex align-center">
                      <v-btn small @click="editItem(item)" class="align-self-auto" outlined color="green">
                          <v-icon
                          small
                          >
                              mdi-magnify
                          </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col sm="3" class="d-flex align-center">
                      <v-btn small @click="removeItem(item)" class="align-self-auto" outlined color="red">
                          <v-icon
                          small
                          >
                              mdi-delete
                          </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col sm="4">
                      <v-checkbox small outlined
                        v-model="item.canModify"
                        label="Permitir edicion"
                        @change="allowUpdates(item)">
                        <v-icon small>
                          circle-edit-outline
                        </v-icon>
                      </v-checkbox>
                    </v-col>
                  </v-row>
                </template>
            </v-data-table>
        </v-col>
    </v-row>
</div>
</template>

<script>
import ClientService from '../services/ClientService';

export default {
  name: 'clients-table',
  data: () => ({
    search: '',
    items: [],
    isTableLoading: true,
    headers: [
      {
        text: 'Id', value: 'id', align: 'center',
      },
      {
        text: 'Nombre de la familia', value: 'name', align: 'center',
      },
      {
        text: 'Persona responsable', value: 'responsible', align: 'center',
      },
      {
        text: 'Personas autorizadas', value: 'authorizedPersons.length', align: 'center', filterable: false,
      },
      {
        text: 'Ni;os', value: 'children.length', align: 'center', filterable: false,
      },
      {
        text: 'Acciones', value: 'action', align: 'center',
      },
    ],
  }),
  methods: {
    editItem(item) {
      this.$router.push(`/${item.id}/edit-client`);
    },
    removeItem(item) {
      this.$swal({
        title: 'Atención',
        text: `¿Estás seguro que quieres dar de baja esta familia?\n${item.name}`,
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Si, eliminar',
      })
        .then((result) => {
          if (result.value) {
            // Llamar servicio de eliminar y quitar elemento de la vista
            this.$snotify.async('Por favor espere...', 'Eliminando familia', () => new Promise((resolve, reject) => {
              ClientService.deleteItem(item.id)
                .then(() => {
                  resolve({
                    title: 'Familia dado de baja',
                    body: 'Se dió de baja con éxito',
                    config: {
                      timeout: 3000,
                    },
                  });
                  this.removeItemFromTable(item);
                })
                .catch(() => {
                  reject({
                    title: 'Error',
                    body: 'No se pudo dar de baja la familia',
                    config: {
                      timeout: 3000,
                    },
                  });
                });
            }));
          }
        });
    },
    removeItemFromTable(item) {
      this.items.splice(this.items.indexOf(item), 1);
    },
    allowUpdates(item) {
      console.log(item.canModify);
      this.$snotify.async('Por favor espere...', 'Permitiendo actualizaciones', () => new Promise((resolve, reject) => {
        ClientService.allowUpdates(item.id, !item.canModify)
          .then(() => {
            resolve({
              title: 'Correcto',
              body: 'Se actualizaron los permisos de la familia',
              config: {
                timeout: 3000,
              },
            });
          })
          .catch(() => {
            reject({
              title: 'Error',
              body: 'Hubo un error en la operacion',
              config: {
                timeout: 3000,
              },
            });
          });
      }));
    },
    downloadReport() {
      ClientService.downloadReport();
    },
  },
  async mounted() {
    this.isTableLoading = true;
    this.items = await ClientService.getTable();
    this.isTableLoading = false;
  },
};
</script>
