<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="12">
        <h1 class="title-text">Tabla de escuelas</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" sm="12">
        <v-data-table
          :items="items"
          :loading="isTableLoading"
          :headers="headers"
          :search="search"
          no-data-text="No hay escuelas registradas"
          :item-class="itemRowBackground"
        >
          <template v-slot:top>
            <v-spacer></v-spacer>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  single-line
                  hide-details
                  label="Buscar"
                  class="mx-4"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="d-flex justify-end">
                <v-btn to="/new-campus" color="secondary" dark class="mb-2">
                  <v-icon class="mr-2">mdi-plus</v-icon> Alta de escuelas
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <template v-slot:item.action="{ item }">
            <v-row>
              <v-col sm="12">
                <v-btn
                  small
                  @click="editItem(item)"
                  class="mr-2"
                  outlined
                  :color="iconColor(item)"
                >
                  <v-icon small> mdi-pencil </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CampusService from '../services/CampusService';

export default {
  name: 'campus-home',
  data: () => ({
    items: [],
    isTableLoading: false,
    search: '',
    headers: [
      {
        text: 'Id', value: 'id', align: 'center', filterable: false,
      },
      {
        text: 'Nombre de la escuela', value: 'name', align: 'center',
      },
      {
        text: 'Nombre corto', value: 'shortname', align: 'center',
      },
      {
        text: 'Direccion', value: 'address', align: 'center',
      },
      {
        text: 'Hora de inicio', value: 'startTime', align: 'center',
      },
      {
        text: 'Hora de salida', value: 'endTime', align: 'center',
      },
      {
        text: 'Acciones', value: 'action', align: 'center',
      },
    ],
  }),
  methods: {
    itemRowBackground(item) {
      return !item.status ? 'campus-status-alert' : '';
    },
    iconColor(item) {
      return item.status ? '#27cf3e' : '#b33834';
    },
    async loadCampusTable() {
      this.items = await CampusService.getTable();
    },
    editItem(item) {
      this.$router.push(`/${item.id}/edit-campus`);
    },
  },
  mounted() {
    this.loadCampusTable();
  },
};
</script>

<style>
  .campus-status-alert {
    background-color: #f56969;
  }
  .campus-status-alert:hover {
    background-color: #f55d5d !important;
  }
</style>
