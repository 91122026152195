import Axios from '@/services/Axios';
import { ErrorResponse } from '../common/models/ErrorResponse';
import { TokenUtils } from '../utils/TokenUtils';

const resource = '/system/survey';

export default {
  async getSurvey() {
    let response = null;
    try {
      response = await Axios.get(`${resource}`,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e.response);
      response = new ErrorResponse(e.response.data);
      throw response;
    }
    return response.data;
  },
  async getSurveyFromId(surveyId) {
    let response = null;
    try {
      response = await Axios.get(`${resource}/${surveyId}`,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e.response);
      response = new ErrorResponse(e.response.data);
      throw response;
    }
    return response;
  },
  async saveSurvey(data) {
    let response = null;
    try {
      response = await Axios.post(`${resource}`, data,
        { headers: { Authorization: `Bearer ${TokenUtils.getToken()}` } });
    } catch (e) {
      console.error(e.response);
      response = new ErrorResponse(e.response.data);
      throw response;
    }
    return response;
  },
};
